import React from "react";

import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";

const QuickManual = ({ open, onClose }) => (

  <Dialog open={open} onClose={onClose}>
    <DialogTitle>How to send invitations</DialogTitle>
    <DialogContent>
      <Typography>
        Sending ACI FMA Handbook invitations is really simple and we offer three ways to do it:
      </Typography>
      <ul>
        <li><Typography>you can paste data from a list of emails in e.g. Excel to create a bulk invitation;</Typography></li>
        <li><Typography>or you can email a Voucher by entering the email in the "email voucher" tab;</Typography></li>
        <li><Typography>or you can copy a Voucher code from the "create voucher" tab and paste it into your own custom email.</Typography></li>
      </ul>
      <Typography>
        You will see Accepted Vouchers and Sent Vouchers in the two boxes at the bottom of the screen.
      </Typography>
      <Typography sx={{mt: 2}}>
        Remember that you should ensure you have the rights to send messages to the users you are inviting.
        Any communications between the parties in this system (your National Association and the users you invite)
        are governed by data protection laws and the data will remain private to the said Parties.
      </Typography>


    </DialogContent>
  </Dialog>
)

export default QuickManual;
