import React from "react";
import {
  Card as MUICard,
  Box,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";

const MyCard = (props) => {
  return (
    <MUICard
      sx={{
        width: 320,
        height: 400,
        padding: 2,
        cursor: "pointer",
        ":hover": { boxShadow: 10 },
      }}
      style={props.cardstyle}
    >
      <Box
        sx={{ width: "100%", height: "60%" }}
        onClick={props.onClick ? () => props.onClick(22) : null}
        disableRipple={!props.onClick}
      >
        <CardMedia sx={{ height: 80, textAlign: "center", marginTop: 2 }}>
          {props.icon}
        </CardMedia>
        <CardContent sx={{ height: 220, padding: 0, margin: 1, overflow: "hidden" }}>{props.children}</CardContent>
        <CardActions sx={{ padding: 0 }}>{props.actions}</CardActions>
      </Box>
    </MUICard>
  );
};

export default MyCard;
