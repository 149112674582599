import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import AssocationSelect from "../AssociationSelect";
import { GET, POST } from "../../lib/API";



const MessageInput = ({ message, setMessage }) => {
  const onChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <FormControl sx={{ paddingTop: 2, width: "100%" }}>
      <TextField
        multiline
        rows={6}
        label="Message"
        sx={{ width: "100%" }}
        onChange={onChange}
      />
    </FormControl>
  );
};

const RequestAccess = ({ open, setOpen }) => {
  const [codes, setCodes] = useState([]);
  const [message, setMessage] = useState("");

  const getCodes = () => {
    GET("/codes")
      .then((res) => res.json())
      .then((res) => setCodes(res));
  };

  useEffect(() => {
    getCodes();
  }, []);

  const [association, setAssociation] = useState("");

  const requestAccess = () => {
    console.log(`Request access for ${association} with ${message}`);
    return POST(`/codes/${codes[0].id}/request_access`, {
      body: JSON.stringify({ association_id: association, message: message }),
    }).then((res) => {
      if (res.ok) {
        alert("Your request has been sent.");
      } else {
        alert("Something went wrong.");
      }
    });
  };

  const send = async () => {
    await requestAccess();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Request Access</DialogTitle>
      <DialogContent>
        <Typography>
          To request access to the ACI FMA handbook please select your ACI
          National Association and press "send request". If you wish you can add
          a message to the ACI FMA admin to accompany your request.
        </Typography>
        <AssocationSelect
          association={association}
          setAssociation={setAssociation}
          hasAdmin={true}
        />
        <MessageInput message={message} setMessage={setMessage} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={send}>Send request</Button>
      </DialogActions>
    </Dialog>
  );
};

RequestAccess.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default RequestAccess;
