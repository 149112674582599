import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  TextField,
} from "@mui/material";

import AssocationSelect from "./AssociationSelect";
import { POST } from "../lib/API";

const MessageInput = ({ message, setMessage }) => {
  const onChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <FormControl sx={{ paddingTop: 2, width: "100%" }}>
      <TextField
        multiline
        rows={6}
        label="Message"
        sx={{ width: "100%" }}
        onChange={onChange}
      />
    </FormControl>
  );
};

const SupportRequest = ({ open, setOpen }) => {

  const [association, setAssociation] = useState("");
  const [message, setMessage] = useState("");

  const send = () => {
    POST("/support", { body: JSON.stringify({ message: message, association: association }) })
      .then(() => setOpen(false));
  }

  return (
    <Dialog open={open} PaperProps={{ style: { width: 400 } }}>
      <DialogTitle>Contact myACIFMA</DialogTitle>
      <DialogContent>
        <AssocationSelect
          association={association}
          setAssociation={setAssociation}
        />
        <MessageInput message={message} setMessage={setMessage} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={send}>Send request</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SupportRequest;