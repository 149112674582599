import React, {useState, useEffect} from "react";
import { Dialog, DialogContent, DialogTitle, Box, Divider } from "@mui/material";

const TermsDialog = ({open, onClose, url}) => {

  const [content, setContent] = useState(false);

  useEffect(() => {
    open && fetch(open).then(res => res.text()).then(res => setContent(res));
  }, [open]);

  return (
    <Dialog open={!!open} onClose={onClose}> 
      <DialogContent>
      <Box dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent> 
    </Dialog>
  )
}

export default TermsDialog;