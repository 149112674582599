import React, {useState} from "react";
import {Button, Typography} from "@mui/material";

import Card from "./Card";

import NoProductIcon from "@mui/icons-material/NewReleases";
import RequestAccess from "./RequestAccess";

const NoProductCard = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <RequestAccess open={open} setOpen={setOpen} />
      <Card
        icon={<NoProductIcon sx={{ fontSize: 50, color: "#E3E2EE" }} />}
        actions={
          <Button style={{ width: "100%", marginRight: 0 }} variant="contained">
            Request access
          </Button>
        }
        onClick={() => setOpen(!open)}
        {...props}
      >
        <Typography sx={{ fontWeight: "bold" }}>ACI FMA Handbook</Typography>
        <Typography>
          Request access to the ACI FMA Handbook - Our Best Practices and Further Guidance: Reinforcing Industry Codes.
        </Typography>
      </Card>
    </>
  );
};

export default NoProductCard;