import React, { createContext, useEffect, useState } from "react";
import { GET, PUT, DELETE } from "../../lib/API";

const CodeReadContext = createContext();

const CodeReadProvider = ({ children, code_id }) => {
  const [reads, setReads] = useState([]);

  const getCodeRead = (content_id) =>
    GET(`/codes/${code_id}/content/${content_id}/read`)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          return Promise.resolve(false);
        }
      })
      .then((res) => {
        return Promise.resolve(res.created_at);
      })
      .catch((err) => {});

  const setCodeRead = (content_id) =>
    PUT(`/codes/${code_id}/content/${content_id}/read`).then(() => {
      reads[content_id] = true;
      setReads({ ...reads });
    });

  const unsetCodeRead = (content_id) =>
    DELETE(`/codes/${code_id}/content/${content_id}/read`).then(() => {
      delete reads[content_id];
      setReads({ ...reads });
    });

  const getCodeReads = () => {
    fetch(`/codes/${code_id}/reads`)
      .then((res) => res.json())
      .then((res) =>
        res.reduce((map, obj) => {
          map[obj.content_id] = obj.created_at;
          return map;
        }, {})
      )
      .then((res) => {
        setReads(res);
        return Promise.resolve(res);
      });
  };

  useEffect(() => {
    getCodeReads();
  }, []);

  return (
    <CodeReadContext.Provider
      value={{ setCodeRead, unsetCodeRead, getCodeRead, reads }}
    >
      {children}
    </CodeReadContext.Provider>
  );
};

const CodeReadConsumer = CodeReadContext.Consumer;
export { CodeReadContext, CodeReadProvider, CodeReadConsumer };
