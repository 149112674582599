import React, { useState, useEffect } from "react";
import CRUD from "../../lib/CRUD";
import { POST, DELETE } from "../../lib/API";
import {
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  DialogContent,
  Grid,
  Tooltip
} from "@mui/material";
import {
  Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon,
  ContactMail as VoucherAdminIcon
} from "@mui/icons-material";

import SelectUserDialog from "../SelectUserDialog";

const UserList = (props) => {
  const { association } = props;
  const [open, setOpen] = useState(false);
  const [admins, setAdmins] = useState([]);

  const fetchAdmins = () => {
    fetch(`/admin/associations/${association.id}/admins`)
      .then((res) => res.json())
      .then((res) => setAdmins(res.users));
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  const addUser = (user) => {
    POST(`/admin/associations/${association.id}/admins`, {
      body: JSON.stringify({ user_id: user.id }),
    }).then(() => {
      setOpen(false);
      fetchAdmins();
    });
  };

  const deleteUser = (user) => {
    if (!window.confirm("Are you sure?")) return;
    DELETE(`/admin/associations/${association.id}/admins/${user.id}`).then(() =>
      fetchAdmins()
    );
  };

  return (
    <List>
      {admins.map((user) => {
        return (
          <ListItem
            key={user.id}
            secondaryAction={<DeleteIcon onClick={() => deleteUser(user)} />}
          >
            {user.email}
          </ListItem>
        );
      })}
      <ListItem onClick={() => setOpen(true)}>
        <AddIcon />
        Add administrator
      </ListItem>
      <SelectUserDialog
        open={open}
        onClose={() => setOpen(false)}
        onClick={addUser}
      />
    </List>
  );
};

const AssociationDetailDialog = (props) => {
  const { association, onClose } = props;

  return (
    <Dialog open={!!association} onClose={onClose}>
      <DialogTitle>National Assocation Details</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={4}>
            Association
          </Grid>
          <Grid item xs={8}>
            {association.name}
          </Grid>
          <Grid item xs={4}>
            Members
          </Grid>
          <Grid item xs={8}>
            {association.member_count}
          </Grid>
          <Grid item xs={4}>
            Admins
          </Grid>
          <Grid item xs={8}>
            <UserList association={association} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const VoucherAdminStatus = ({ association }) => (
  <>
    {
      association.admins?.length > 0 ?
        <Tooltip title={`Voucher admins: ${association.admins.map(u => u.email).join(",")}`}><VoucherAdminIcon /></Tooltip>
        : null
    }
  </>
)


const Item = (props) => {
  const { data, onDelete, onEdit, onClick } = props;
  return (
    <ListItem
      secondaryAction={
        <>
          <IconButton onClick={onEdit} ><EditIcon /></IconButton>
          <IconButton onClick={onDelete} ><DeleteIcon /></IconButton>
        </>
      }
    >
      <ListItemText style={{ flex: "1 1 300px" }} onClick={() => onClick && onClick(data)}>
        {data.name}
      </ListItemText>
      <Tooltip title="Total allocation / Pending / Accepted">
        <ListItemText
          style={{ flex: "1 1 100px" }}
          primary="Vouchers"
          secondary={`${data.member_count}/${data.consumable_voucher_count}/${data.consumed_voucher_count}`}
        />
      </Tooltip>
      <ListItemText
        style={{ width: 50 }}
        primary={<VoucherAdminStatus association={data} />}
      />
    </ListItem>
  )
}

const Associations = () => {
  const [dialog, setDialog] = useState(false);

  const association_spec = [
    { id: "name", label: "Association" },
    { id: "member_count", label: "Number of members" },
  ];

  const onOpen = (d) => {
    setDialog(d);
  };
  const onClose = () => {
    setDialog(false);
  };

  return (
    <>
      <CRUD
        spec={association_spec}
        namespace="admin"
        model="association"
        title="Association"
        onClick={onOpen}
        listItem={Item}
      />
      {dialog && (
        <AssociationDetailDialog association={dialog} onClose={onClose} />
      )}
    </>
  );
};

export default Associations;
