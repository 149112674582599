import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

const Asset = (props) => {
  const { asset } = props;

  switch (asset.type) {
    case "picture":
      return <img src={asset.url} />;
    default:
      return null;
  }
};

const CodeContent = (props) => {
  const { codeId, contentId, content, setContent } = props;

  const fetchContent = () => {
    fetch(`/codes/${codeId}/content/${contentId}`)
      .then((res) => res.json())
      .then((res) => setContent(res))
      .then(() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" }));
  };

  useEffect(() => {
    if (codeId && contentId) fetchContent();
  }, [contentId]);

  if (!content) return null;

  return (
    <div className="codecontent">
      <div className="header" />
      <h2>{content.breadcrumbs.slice(-1)[0]}</h2>
      <h3>{content.title}</h3>
      {content.assets?.map((asset) => (
        <Asset key={asset.id} asset={asset} />
      ))}
      <Box dangerouslySetInnerHTML={{ __html: content.body }} />
      <div className="footer" />
    </div>
  );
};

export default CodeContent;
