import React from "react";

const InfoIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="code"
        transform="translate(-1512.000000, -20.000000)"
        fill="#000000"
        fillRule="nonzero"
      >
        <g
          id="info_FILL0_wght400_GRAD0_opsz48"
          transform="translate(1512.000000, 20.000000)"
        >
          <path
            d="M11.19,18 L12.99,18 L12.99,10.8 L11.19,10.8 L11.19,18 Z M12,8.58 C12.28,8.58 12.515,8.49 12.705,8.31 C12.895,8.13 12.99,7.9 12.99,7.62 C12.99,7.34 12.895,7.1 12.705,6.9 C12.515,6.7 12.28,6.6 12,6.6 C11.72,6.6 11.485,6.7 11.295,6.9 C11.105,7.1 11.01,7.34 11.01,7.62 C11.01,7.9 11.105,8.13 11.295,8.31 C11.485,8.49 11.72,8.58 12,8.58 L12,8.58 Z M12,24 C10.36,24 8.81,23.685 7.35,23.055 C5.89,22.425 4.615,21.565 3.525,20.475 C2.435,19.385 1.575,18.11 0.945,16.65 C0.315,15.19 0,13.63 0,11.97 C0,10.33 0.315,8.78 0.945,7.32 C1.575,5.86 2.435,4.59 3.525,3.51 C4.615,2.43 5.89,1.575 7.35,0.945 C8.81,0.315 10.37,0 12.03,0 C13.67,0 15.22,0.315 16.68,0.945 C18.14,1.575 19.41,2.43 20.49,3.51 C21.57,4.59 22.425,5.86 23.055,7.32 C23.685,8.78 24,10.34 24,12 C24,13.64 23.685,15.19 23.055,16.65 C22.425,18.11 21.57,19.385 20.49,20.475 C19.41,21.565 18.14,22.425 16.68,23.055 C15.22,23.685 13.66,24 12,24 Z M12.03,22.2 C14.85,22.2 17.25,21.205 19.23,19.215 C21.21,17.225 22.2,14.81 22.2,11.97 C22.2,9.15 21.21,6.75 19.23,4.77 C17.25,2.79 14.84,1.8 12,1.8 C9.18,1.8 6.775,2.79 4.785,4.77 C2.795,6.75 1.8,9.16 1.8,12 C1.8,14.82 2.795,17.225 4.785,19.215 C6.775,21.205 9.19,22.2 12.03,22.2 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
export default InfoIcon;
