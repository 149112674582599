import React from "react";

const AccountIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="overview"
        transform="translate(-1552.000000, -20.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g
          id="account_circle_FILL1_wght400_GRAD0_opsz48"
          transform="translate(1552.000000, 20.000000)"
        >
          <path
            d="M4.26,18.75 C5.52,17.95 6.765,17.345 7.995,16.935 C9.225,16.525 10.56,16.32 12,16.32 C13.44,16.32 14.78,16.525 16.02,16.935 C17.26,17.345 18.51,17.95 19.77,18.75 C20.65,17.67 21.275,16.58 21.645,15.48 C22.015,14.38 22.2,13.22 22.2,12 C22.2,9.1 21.225,6.675 19.275,4.725 C17.325,2.775 14.9,1.8 12,1.8 C9.1,1.8 6.675,2.775 4.725,4.725 C2.775,6.675 1.8,9.1 1.8,12 C1.8,13.22 1.99,14.38 2.37,15.48 C2.75,16.58 3.38,17.67 4.26,18.75 Z M12,12.9 C10.84,12.9 9.865,12.505 9.075,11.715 C8.285,10.925 7.89,9.95 7.89,8.79 C7.89,7.63 8.285,6.655 9.075,5.865 C9.865,5.075 10.84,4.68 12,4.68 C13.16,4.68 14.135,5.075 14.925,5.865 C15.715,6.655 16.11,7.63 16.11,8.79 C16.11,9.95 15.715,10.925 14.925,11.715 C14.135,12.505 13.16,12.9 12,12.9 Z M12,24 C10.32,24 8.75,23.685 7.29,23.055 C5.83,22.425 4.56,21.565 3.48,20.475 C2.4,19.385 1.55,18.11 0.93,16.65 C0.31,15.19 0,13.63 0,11.97 C0,10.33 0.315,8.78 0.945,7.32 C1.575,5.86 2.435,4.59 3.525,3.51 C4.615,2.43 5.89,1.575 7.35,0.945 C8.81,0.315 10.37,0 12.03,0 C13.67,0 15.22,0.315 16.68,0.945 C18.14,1.575 19.41,2.43 20.49,3.51 C21.57,4.59 22.425,5.86 23.055,7.32 C23.685,8.78 24,10.34 24,12 C24,13.64 23.685,15.19 23.055,16.65 C22.425,18.11 21.57,19.385 20.49,20.475 C19.41,21.565 18.14,22.425 16.68,23.055 C15.22,23.685 13.66,24 12,24 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default AccountIcon;
