import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Dialog, DialogTitle, DialogContent, Typography, Divider, ButtonBase, Box, Collapse } from "@mui/material";
import { ArrowLeft, ArrowDropDown } from "@mui/icons-material";

const CodeInfo = ({ open, onClose }) => {

  const [content, setContent] = useState(false);
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    fetch("/disclaimer?partial=1").then(res => res.text()).then(res => setContent(res));
  }, []);

  const year = new Date().getFullYear();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>About this Code</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
        <Divider />
        <Typography>About the ACI FMA Handbook</Typography>
        <Typography>
          ACI FMA Handbook - Our Best Practices and Further Guidance:
          Reinforcing Industry Codes is intended to support the FX
          Global Code, Global Precious Metals Code and UK Money Markets
          Code by reinforcing their best practice guidelines.
        </Typography>
        <Typography>
          The ACI FMA Handbook is produced by ACI Financial Markets Association.
        </Typography>
        <Typography>
          Copyright © {year} ACI Financial Markets Association All Rights Reserved.
        </Typography>
        <Divider />
        <ButtonBase sx={{ justifyContent: "left" }} onClick={() => setCollapse(!collapse)}>
          <Typography variant="h6" sx={{ display: "inline" }}>
            Disclaimer
          </Typography>
          <Box sx={{ display: "inline-block" }}>
            {collapse ? <ArrowDropDown /> : <ArrowLeft />}
          </Box>
        </ButtonBase>
        <Collapse in={collapse} style={{ minHeight: "auto" }}>
          <Box dangerouslySetInnerHTML={{ __html: content }} />
        </Collapse>
        <Divider />
        <Box>
          <Typography sx={{ fontWeight: "bold", display: "inline" }}>Created	</Typography>
          <Typography sx={{ display: "inline", float: "right" }}>		May 2022</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
CodeInfo.propType = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default CodeInfo;