import React from "react";
import { useEffect, useContext, useState } from "react";
import {
  Box,
  InputBase,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import { parseISO, format } from "date-fns";
import SearchIcon from "@mui/icons-material/Search";
import { VoucherContext } from "./VoucherContext";

const SearchBox = (props) => (
  <Box component="form" sx={{ display: "flex" }}>
    <SearchIcon sx={{ p: 1 }} />
    <InputBase placeholder="Search" onChange={props.onChange} />
  </Box>
);

const ConsumedVoucher = ({ voucher }) => {
  const user = voucher.consumed_by;
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar src={user.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={`${user.first_name} ${user.last_name}`}
        secondary={user.email}
      />
      <ListItemText
        primary="Accepted at"
        secondary={format(parseISO(voucher.consumed_at), "yyyy/MM/dd HH:mm")}
      />
    </ListItem>
  );
};

const ListMembers = () => {
  const { fetchConsumed, consumed } = useContext(VoucherContext);

  const [filter, setFilter] = useState("");

  useEffect(() => {
    fetchConsumed();
  }, []);

  const onChange = (event) => {
    setFilter(event.target.value);
  };

  const filterByText = (value) => {
    if (!filter) return true;
    return (
      value.consumed_by.email.includes(filter) ||
      value.consumed_by.first_name.includes(filter) ||
      value.consumed_by.last_name.includes(filter)
    );
  };

  return (
    <>
      <SearchBox onChange={onChange} />
      <Divider />
      <List>
        {consumed &&
          consumed
            .filter(filterByText)
            .map((voucher) => (
              <ConsumedVoucher key={voucher.id} voucher={voucher} />
            ))}
      </List>
    </>
  );
};

export default ListMembers;
