import React from "react";
import { useEffect, useContext, useState } from "react";
import {
  Box,
  InputBase,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { VoucherContext } from "./VoucherContext";
import { parseISO, format } from "date-fns";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";

const SearchBox = (props) => (
  <Box component="form" sx={{ display: "flex" }}>
    <SearchIcon sx={{ p: 1 }} />
    <InputBase placeholder="Search" onChange={props.onChange} />
  </Box>
);
const Voucher = ({ voucher }) => {
  const user = voucher.sent_to ? voucher.sent_to : "Anonymous";

  if (voucher.sent_to) {
    return (
      <ListItem>
        <ListItemText sx={{ width: "40%" }} primary={voucher.sent_to} />
        <ListItemText
          primary="Sent at"
          secondary={format(parseISO(voucher.created_at), "yyyy/MM/dd HH:mm")}
        />
        <ListItemText
          primary="Valid until"
          secondary={format(parseISO(voucher.valid_until), "yyyy/MM/dd HH:mm")}
        />
      </ListItem>
    );
  } else {
    return (
      <ListItem>
        <ListItemText sx={{ width: "40%" }} primary="Anonymous" />
        <ListItemText
          primary="Created At"
          secondary={format(parseISO(voucher.created_at), "yyyy/MM/dd HH:mm")}
        />
        <ListItemText
          primary="Valid until"
          secondary={format(parseISO(voucher.valid_until), "yyyy/MM/dd HH:mm")}
        />
      </ListItem>
    );
  }
};

const ListInvitations = () => {
  const { fetchOutstanding, outstanding } = useContext(VoucherContext);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    fetchOutstanding();
  }, []);

  const onChange = (event) => {
    setFilter(event.target.value);
  };

  const filterByText = (value) => {
    if (!filter) return true;
    return value.sent_to && value.sent_to.includes(filter);
  };

  return (
    <>
      <SearchBox onChange={onChange} />
      <Divider />
      <List>
        {outstanding &&
          outstanding
            .filter(filterByText)
            .map((voucher) => <Voucher key={voucher.id} voucher={voucher} />)}
      </List>
    </>
  );
};

export default ListInvitations;
