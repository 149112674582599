import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  zIndex: {
    // Make drawer stay underneath AppBar
    drawer: 1080,
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "#F8F8FC",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "usual",
          color: "#2C2A56",
        },
        h2: {
          fontSize: 24,
          fontWeight: 700
        },
        h3: {
          fontSize: 21,
          fontWeight: 600
        }
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#2C2A56",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          marginTop: 64,
          width: 300,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: "1rem",
          fontFamily: "usual",
          fontWeight: 600,
          marginRight: 10,
          textTransform: "none",
          backgroundColor: "#2C2A56",
          color: "white",
          "&:hover": {
            backgroundColor: "#19173A",
          },
          "&.Mui-disabled": {
            backgroundColor: "transparent",
            border: "2px solid #D7D7E3",
            color: "#D7D7E3"
          }
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#D8D8D8",
            "&:hover": {
              backgroundColor: "#D0D0D0",
            },
          },
          "&:hover": {
            backgroundColor: "#D0D0D0",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#1876D2",
          maxWidth: 500,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 26,
          fontWeight: 500
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingBottom: 15
        }
      }
    }
  },
});

export default (props) => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
