import { define } from "remount";
import MenuBar from "./components/MenuBar";
import AdminUsers from "./components/Admin/AdminUsers";
import Products from "./components/Products";
import Associations from "./components/Admin/AdminAssociations";
import CodeBrowser from "./components/CodeBrowser";
import VoucherConsumer from "./components/VoucherConsumer";
import ManageVouchers from "./components/ManageVouchers";
import ProductBrowser from "./components/ProductBrowser";

define(
  { "menu-bar": MenuBar },
  { attributes: ["admin", "association_admin", "menu", "menu_title"] }
);
define({ "admin-users": AdminUsers });
define({ "admin-products": Products });
define({ "admin-associations": Associations });
define({ "code-browser": CodeBrowser }, { attributes: ["code_id"] });
define({ "voucher-consumer": VoucherConsumer });
define(
  { "manage-vouchers": ManageVouchers },
  { attributes: ["association_name", "association_id"] }
);
define({ "product-browser": ProductBrowser });
