import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import Card from "./Card";
import SupportIcon from "@mui/icons-material/HelpOutline";
import SupportRequest from "../SupportRequest";

const SupportCard = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SupportRequest open={open} setOpen={setOpen} />
      <Card
        icon={<SupportIcon sx={{ fontSize: 50, color: "#E3E2EE" }} />}
        actions={
          <Button style={{ width: "100%", marginRight: 0 }} variant="contained">
            Contact myACIFMA
          </Button>
        }
        onClick={() => setOpen(!open)}
        {...props}
      >
        <Typography>
          Contact us for questions about your account or myACIFMA content.
        </Typography>
      </Card>
    </>
  );
};

export default SupportCard;