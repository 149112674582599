import React, { useState, useEffect } from "react";
import {
  Avatar,
  ListItemAvatar,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
  Box
} from "@mui/material";
import { parseISO, format } from "date-fns";
import {
  PriorityHigh as ExclamationIcon,
  List as ListIcon,
  Delete,
  Description as ProductIcon,
  SupervisorAccount as AdminIcon,
  ContactMail as VoucherAdminIcon
} from "@mui/icons-material";


const ConfirmationStatus = ({ user }) => (
  user.confirmed_at ? <Box style={{ width: 24 }} /> :
    <Tooltip title="Warning: this user has registered but not confirmed!">
      <ExclamationIcon style={{ color: "red" }} />
    </Tooltip>
)

const ProductStatus = ({ user }) => (
  <>
    {user.products.length > 0 ?
      <Tooltip title={`Product(s): ${user.products.map(p => p.name).join(",")}`}>
        <ProductIcon />
      </Tooltip>
      : null
    }
  </>
)


const AdminStatus = ({ user }) => (
  <>
    {
      user.admin ?
        <Tooltip title="System admin"><AdminIcon /></Tooltip>
        : null
    }
  </>
)

const VoucherAdminStatus = ({ user }) => (
  <>
    {
      user.national_associations.length > 0 ?
        <Tooltip title={`Voucher admin for ${user.national_associations.map(na => na.name).join(",")}`}><VoucherAdminIcon /></Tooltip>
        : null
    }
  </>
)

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [csrfToken, setCsrfToken] = useState();

  const fetch_users = () => {
    fetch("/admin/user_details.json")
      .then((res) => res.json())
      .then((res) => {
        setUsers(res.users);
        setCsrfToken(res.csrfToken);
      });
  };

  useEffect(() => {
    fetch_users();
  }, []);

  const onDelete = (id) => {
    if (!window.confirm("Are you sure?")) return;
    fetch(`/admin/user_details/${id}`, {
      method: "DELETE",
      headers: { "X-CSRF-Token": csrfToken },
    }).then(() => {
      fetch_users();
    });
  };

  return (
    <Paper
      elevation={0}
      style={{ padding: 10, width: 800 }}
    >
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {users.map((user) => {
          return (
            <ListItem
              key={user.id}
              secondaryAction={
                <>
                  <IconButton onClick={() => onDelete(user.id)}>
                    <Delete />
                  </IconButton>
                </>
              }
            >
              <ListItemAvatar>
                <Avatar
                  alt={`${user.first_name} ${user.last_name}`}
                  src={user.avatar}
                />
              </ListItemAvatar>
              <ListItemText
                style={{ flex: "1 1 200px" }}
                primary={`${user.first_name} ${user.last_name}`}
                secondary={user.email}
              />
              <ConfirmationStatus user={user} />
              <ListItemText
                style={{ flex: "1 1 200px" }}
                primary="Registration date"
                secondary={format(parseISO(user.created_at), "yyyy/MM/dd HH:mm")}
              />
              <ListItemText
                style={{ width: 25 }}
                primary={<ProductStatus user={user} />}
              />
              <ListItemText
                style={{ width: 50 }}
                primary={<><AdminStatus user={user} /><VoucherAdminStatus user={user} /></>}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

export default AdminUsers;
