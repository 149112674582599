import React, { useState, useEffect, useContext } from "react";
import { format, parseISO } from "date-fns";
import { Paper, Box, Checkbox, Typography, Tooltip } from "@mui/material";
import { CodeReadContext } from "./CodeReadContext";

const ReadStatus = (props) => {
  const { code_id, content_id } = props;

  const [checked, setChecked] = useState(false);

  const { getCodeRead, setCodeRead, unsetCodeRead } =
    useContext(CodeReadContext);

  useEffect(() => {
    getCodeRead(content_id).then((res) => setChecked(res));
  }, [code_id, content_id]);

  const onClick = () => {
    if (checked) {
      // remove read record
      unsetCodeRead(content_id).then(() => setChecked(false));
    } else {
      // add read record
      setCodeRead(content_id).then(() => setChecked(new Date().toISOString()));
    }
  };

  const formatted_date = () => {
    try {
      return format(parseISO(checked), "'Read on 'EEE yyyy/MM/dd 'at' HH:mm");
    }
    catch (err) {
      return "";
    }
  }

  return (
    <Tooltip title={formatted_date()}>
      <Paper
        onClick={onClick}
        style={{
          cursor: "pointer",
          display: "inline-block",
          backgroundColor: "#FDC300",
          width: "100%",
        }}
      >
        <Checkbox checked={!!checked} />
        <Typography style={{ display: "inline" }}>
          I have read this entire section
        </Typography>
      </Paper>
    </Tooltip>
  );
};

export default ReadStatus;
