import React from "react";

const CodeIcon = (props) => (
  <svg
    width="52px"
    height="64px"
    viewBox="0 0 52 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="overview"
        transform="translate(-282.000000, -309.000000)"
        fill="#E3E2EE"
        fillRule="nonzero"
      >
        <g
          id="description_FILL1_wght400_GRAD0_opsz48"
          transform="translate(282.000000, 309.000000)"
        >
          <path
            d="M12.72,50.4 L38.48,50.4 L38.48,45.6 L12.72,45.6 L12.72,50.4 Z M12.72,36.8 L38.48,36.8 L38.48,32 L12.72,32 L12.72,36.8 Z M4.8,64 C3.52,64 2.4,63.52 1.44,62.56 C0.48,61.6 0,60.48 0,59.2 L0,4.8 C0,3.52 0.48,2.4 1.44,1.44 C2.4,0.48 3.52,0 4.8,0 L33.68,0 L51.2,17.52 L51.2,59.2 C51.2,60.48 50.72,61.6 49.76,62.56 C48.8,63.52 47.68,64 46.4,64 L4.8,64 Z M31.28,19.68 L46.4,19.68 L31.28,4.8 L31.28,19.68 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
export default CodeIcon;
