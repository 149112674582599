import React from "react";
import { Box, Button } from "@mui/material";

const Navigation = (props) => {
  const { onPrev, onNext } = props;
  return (
    <Box>
      {onPrev && (
        <Button variant="contained" onClick={onPrev}>
          Prev
        </Button>
      )}
      {onNext && (
        <Button variant="contained" onClick={onNext}>
          Next
        </Button>
      )}
    </Box>
  );
};

export default Navigation;
