import React from "react";
import MenuButton from "./MenuButton";
import { MenuItem, Link } from "@mui/material";

const MenuOptions = ({ menu, menu_title }) => {
  const menuData = (() => {
    try {
      return JSON.parse(menu);
    } catch (error) {
      return false;
    }
  })();

  if (!menuData) {
    return null;
  } else if (menuData.length == 1) {
    return menu_title;
  } else {
    return (
      <MenuButton icon={menu_title}>
        {menuData.map((item) => (
          <MenuItem
            component={Link}
            key={item.id}
            href={`/manage_vouchers/${item.id}`}
          >
            {item.name}
          </MenuItem>
        ))}
      </MenuButton>
    );
  }
};

export default MenuOptions;
