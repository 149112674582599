import React, { useState, useEffect } from "react";
import { Grid, Paper, Box, Typography, Divider } from "@mui/material";
import { GET } from "../lib/API";
import Stats from "./ManageVouchers/Stats";
import CreateVoucher from "./ManageVouchers/CreateVoucher";
import ListMembers from "./ManageVouchers/ListMembers";
import ListInvitations from "./ManageVouchers/ListInvitations";
import ThemeProvider from "../theme/ThemeProvider";
import InfoIcon from "../icons/InfoIconBlack";
import QuickManual from "./ManageVouchers/QuickManual.jsx";

import {
  VoucherProvider,
} from "./ManageVouchers/VoucherContext";

const MyBox = (props) => (
  <Grid item xs={6} sx={{ padding: 2 }}>
    <Paper elevation={3} sx={{ padding: 1, height: "100%" }}>
      <Box sx={{ padding: 1 }}>
        <Typography sx={{ display: "inline" }} variant="h5">{props.title}</Typography>
        {props.icon && <Box sx={{ display: "inline", pl: 2 }}>{props.icon}</Box>}
      </Box>
      <Divider />
      {props.children}
    </Paper>
  </Grid>
);

const Info = (props) => {

  const [open, setOpen] = useState(false);

  return (
    <>
      <QuickManual open={open} onClose={() => setOpen(false)} />
      <Box onClick={() => setOpen(!open)} sx={{ cursor: "pointer", display: "inline" }}><InfoIcon /></Box>
    </>
  )
}

const ManageVouchers = (props) => {
  const { association_id, association_name } = props;
  const [product, setProduct] = useState(false);

  const getProduct = () => {
    GET("/codes")
      .then((res) => res.json())
      .then((res) => setProduct(res[0]));
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <ThemeProvider>
      <VoucherProvider
        association_id={association_id}
        association_name={association_name}
      >
        <Grid container gap={0}>
          <Grid item xs={12} sx={{ padding: 2 }}>
            <Typography variant="h4">{product.name}</Typography>
          </Grid>
          <MyBox title="Voucher Statistics">
            <Stats />
          </MyBox>
          <MyBox title="Invite Members" icon={<Info />}>
            <CreateVoucher />
          </MyBox>
          <MyBox title="Accepted Vouchers">
            <ListMembers />
          </MyBox>
          <MyBox title="Sent Vouchers">
            <ListInvitations />
          </MyBox>
        </Grid>
      </VoucherProvider>
    </ThemeProvider>
  );
};

export default ManageVouchers;
