import React from "react";

const CrossIcon = () => (
  <svg
    width="17px"
    height="17px"
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="code"
        transform="translate(-1559.000000, -23.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(1556.000000, 20.000000)">
          <g
            id="close_FILL0_wght400_GRAD0_opsz48"
            transform="translate(3.600000, 3.600000)"
          >
            <polygon
              id="Path"
              points="1.26 16.38 1.0658141e-15 15.12 6.93 8.19 1.0658141e-15 1.26 1.26 3.8474397e-31 8.19 6.93 15.12 -1.0658141e-15 16.38 1.26 9.45 8.19 16.38 15.12 15.12 16.38 8.19 9.45"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default CrossIcon;
