import React, {useState} from "react";
import PropTypes from "prop-types";
import MenuButton from "./MenuButton";
import { MenuItem, Link } from "@mui/material";
import AccountIcon from "../../icons/AccountIcon";
import { csrfToken } from "../../lib/meta";
import TermsDialog from "../TermsDialog";

const signOut = () => {
  fetch("/users/sign_out", {
    method: "DELETE",
    headers: {
      "X-CSRF-token": csrfToken(),
    },
  }).then(() => (window.location = "/"));
};

const UserMenu = ({ admin, association_admin }) => {
  const [showTerms, setShowTerms] = useState(false);

  return (
    <MenuButton icon={<AccountIcon />}>
      {association_admin === "1" && (
        <MenuItem component={Link} href="/manage_vouchers">
          Manage Vouchers
        </MenuItem>
      )}
      {admin === "1" && (
        <MenuItem component={Link} href="/admin/user_details">
          User Admin
        </MenuItem>
      )}
      {admin === "1" && (
        <MenuItem component={Link} href="/admin/associations">
          Association Admin
        </MenuItem>
      )}
      {admin === "1" && (
        <MenuItem component={Link} href="/admin/products">
          Product Admin
        </MenuItem>
      )}
      <TermsDialog open={showTerms} onClose={() => setShowTerms(false)}/>
      <MenuItem component={Link} onClick={() => setShowTerms("/terms")}>
        Terms and Conditions
      </MenuItem>
      <MenuItem component={Link} onClick={() => setShowTerms("/privacy")}>
        Privacy Policy
      </MenuItem>
      <MenuItem component={Link} onClick={signOut}>
        Sign out
      </MenuItem>
    </MenuButton>
  )
};

UserMenu.propTypes = {
  admin: PropTypes.string,
  association_admin: PropTypes.string,
};

export default UserMenu;
