import React, { useState, useContext } from "react";
import {
  Dialog, DialogContent, DialogActions, DialogTitle,
  TextField,
  Button,
  List, ListItem, ListItemIcon, ListItemText,
  IconButton, Tooltip,
  Box, Typography,
  CircularProgress
} from "@mui/material"

import { VoucherContext } from "./VoucherContext";
import CorrectIcon from "@mui/icons-material/Check";
import InvalidIcon from "@mui/icons-material/Clear";
import DuplicateIcon from "@mui/icons-material/Checklist";
import UndefinedIcon from "@mui/icons-material/QuestionMark";
import DeleteIcon from "@mui/icons-material/Delete";

const StatusIcon = ({ status }) => {
  switch (status) {
    case "correct":
      return (<CorrectIcon style={{ color: "green" }} />)
    case "invalid":
      return (<InvalidIcon style={{ color: "red" }} />)
    case "exists":
      return (<DuplicateIcon style={{ color: "#FFBF00" }} />)
    default:
      return (<UndefinedIcon style={{ color: "red" }} />)
  }
}

const statusText = (status) => {
  switch (status) {
    case "correct":
      return "This email is correct.";
    case "invalid":
      return "This email is invalid, you need to remove it before you can send the invitations.";
    case "exists":
      return "This email has already received an invitation.  We recommend you do not send another one, but check if the user has received and accepted his Voucher already.";
    default:
      return "Unknown status.";
  }
}

const MailItem = ({ email, onDelete }) => (
  <ListItem secondaryAction={<IconButton onClick={onDelete} ><DeleteIcon /></IconButton>}>
    <Tooltip title={statusText(email.status)}>
      <ListItemIcon><StatusIcon status={email.status} /></ListItemIcon>
    </Tooltip>
    <Tooltip title={statusText(email.status)}>
      <ListItemText primary={email.sent_to} />
    </Tooltip>
  </ListItem>
)

const MailList = ({ mailList, onDelete }) => {
  return (
    <List>
      {mailList.map((email, idx) => <MailItem key={idx} email={email} onDelete={() => onDelete(idx)} />)}
    </List>
  )
}

const BulkEmailDialog = (props) => {
  const { open, setOpen } = props;
  const [textArea, setTextArea] = useState("");
  const [mailList, setMailList] = useState([]);
  const [final, setFinal] = useState(false);
  const [finalResponse, setFinalResponse] = useState([]);

  const { check_email, send_emails, available_vouchers } = useContext(VoucherContext);

  const checkEmails = () => {
    const email_array = textArea.replace(/[;,\n]/g, ' ').split(' ').filter(s => s);
    check_email(email_array.map(email => ({ sent_to: email })))
      .then(res => setMailList(res.vouchers));
  }

  const onChange = (event) => {
    setTextArea(event.target.value);
  }

  const onDelete = (idx) => {
    delete mailList[idx];
    setMailList(mailList.filter(x => x));
  }

  const onClose = () => {
    setTextArea("");
    setMailList([]);
    setFinalResponse([]);
    setFinal(false);
    setOpen(false);
  }

  const sendMails = () => {
    setFinal(true)
    send_emails(mailList)
      .then(res => setFinalResponse(res));
  }

  const errors = () => {
    return mailList.filter(x => x.status == "invalid").length;
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Bulk Invitation</DialogTitle>
      {final ?
        <>
          <DialogContent sx={{ minWidth: 500, maxWidth: 600 }}>
            {
              finalResponse.length == 0 ?
                <Box sx={{ textAlign: "center" }}>
                  <Typography sx={{ pb: 1 }}>Sending vouchers .... </Typography>
                  <CircularProgress />
                </Box>
                :
                <Typography>Sent {finalResponse.length} vouchers</Typography>
            }
          </DialogContent>
          <DialogActions>
            <Button disabled={finalResponse.length == 0} onClick={onClose}>Close</Button>
          </DialogActions>
        </>
        :
        (
          mailList.length == 0 ?
            <>
              <DialogContent sx={{ minWidth: 500, maxWidth: 600 }}>
                <TextField
                  placeholder={"Paste your list of emails here.\n \nOnly plain email addresses (e.g. john@boe.com) are supported."}
                  multiline={true} minRows={10}
                  sx={{ width: 500 }}
                  onChange={onChange}
                  value={textArea}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={checkEmails}>Check</Button>
              </DialogActions>
            </>
            :
            <>
              <DialogTitle>
                {mailList.length > available_vouchers() &&
                  <Box>
                    <Typography sx={{ color: "red" }}>
                      The number of emails exceeds the available Voucher allocation.
                      Please remove <b>{mailList.length - available_vouchers()}</b> addresses.
                      Contact the myACIFMA team to request more Vouchers.
                    </Typography>
                  </Box>
                }
              </DialogTitle>
              <DialogContent sx={{ minWidth: 500, maxWidth: 600 }}>
                <MailList mailList={mailList} onDelete={onDelete} />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setMailList([])}>Back</Button>
                <Button disabled={errors() > 0 || mailList.length > available_vouchers()} onClick={sendMails}>Send Invitations</Button>
              </DialogActions>
            </>
        )}
    </Dialog>
  )
}

export default BulkEmailDialog;