import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GET } from "../../lib/API";
import { Box, Typography } from "@mui/material";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import parseISO from "date-fns/parseISO";
import CodeIcon from "../../icons/CodeIcon";
import Card from "./Card";

const ProductCard = (props) => {
  const [lastSeen, setLastSeen] = useState(false);
  const [percentRead, setPercentRead] = useState(0);

  const getLastSeen = () => {
    GET(`/codes/${props.product_id}/last_seen`)
      .then((res) => res.status == 200 && res.json())
      .then((res) => setLastSeen(res.created_at));
  };

  const getReads = () => {
    Promise.all([
      fetch(`/codes/${props.product_id}/reads`),
      fetch(`/codes/${props.product_id}`),
    ])
      .then((res) => Promise.all(res.map((r) => r.json())))
      .then(([nread, index]) => {
        setPercentRead(
          Math.round((100.0 * nread.length) / index.contentIds.length)
        );
      });
  };

  useEffect(() => {
    getReads();
    getLastSeen();
  }, []);

  return (
    <Card icon={<CodeIcon />} {...props}>
      <Box style={{ paddingTop: 20 }}>
        <Typography style={{ fontSize: 15, display: "inline" }}>
          Reading Progress{" "}
        </Typography>
        <Typography
          style={{ fontWeight: "bold", color: "#69AC83", display: "inline" }}
        >
          {percentRead ? percentRead : 0}%
        </Typography>
      </Box>
      <Typography style={{ fontSize: 21, fontWeight: "bold", paddingTop: 10 }}>
        {props.name}
      </Typography>
      {lastSeen && (
        <Typography style={{ fontSize: 14, color: "#7C7AA7", paddingTop: 10 }}>
          Last read {formatDistanceToNow(parseISO(lastSeen))} ago
        </Typography>
      )}
    </Card>
  );
};

ProductCard.propTypes = {
  product_id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProductCard;
