import React, { createContext, useState, useEffect } from "react";
import { GET, POST } from "../../lib/API";

const VoucherContext = createContext();

const VoucherProvider = ({ children, association_id }) => {
  const [association, setAssociation] = useState();
  const [consumed, setConsumed] = useState([]);
  const [outstanding, setOutstanding] = useState([]);

  const create_voucher = () => {
    return POST(`/manage_vouchers/${association_id}/create`, {
      body: JSON.stringify({}),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchOutstanding();
        return Promise.resolve(res.url)
      });
  };

  const email_voucher = (email) => {
    return POST(`/manage_vouchers/${association_id}/email`, {
      body: JSON.stringify({
        voucher: { sent_to: email },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchOutstanding();
        return Promise.resolve();
      });
  };

  const check_email = (emails) => {
    return POST(`/manage_vouchers/${association_id}/check_email`, {
      body: JSON.stringify({ vouchers: emails })
    })
      .then((res) => res.json());
  }

  const send_emails = (emails) => {
    return POST(`/manage_vouchers/${association_id}/email`, {
      body: JSON.stringify({ vouchers: emails })
    })
      .then((res) => res.json())
      .then((res) => {
        fetchOutstanding();
        return Promise.resolve(res);
      });
  }

  const fetchConsumed = () => {
    return GET(`/associations/${association_id}/vouchers?consumed=yes`)
      .then((res) => res.json())
      .then((res) => {
        setConsumed(res);
        return Promise.resolve();
      });
  };
  const fetchOutstanding = () => {
    return GET(`/associations/${association_id}/vouchers?consumed=no`)
      .then((res) => res.json())
      .then((res) => {
        setOutstanding(res);
        return Promise.resolve();
      });
  };

  const getAssociationData = () => {
    return GET(`/manage_vouchers/${association_id}`)
      .then((res) => res.json())
      .then((res) => setAssociation(res));
  };

  const available_vouchers = () => {
    if (association) {
      return association.member_count - consumed.length - outstanding.length;
    }
    return "";
  }

  useEffect(() => {
    getAssociationData();
  }, []);

  return (
    <VoucherContext.Provider
      value={{
        association,
        create_voucher,
        email_voucher,
        check_email,
        send_emails,
        consumed,
        fetchConsumed,
        outstanding,
        fetchOutstanding,
        available_vouchers
      }}
    >
      {children}
    </VoucherContext.Provider>
  );
};

const VoucherConsumer = VoucherContext.Consumer;

export { VoucherConsumer, VoucherProvider, VoucherContext };
