import React, { useState, useEffect } from "react";
import { Container, Grid, Button, Paper } from "@mui/material";
import MenuBar from "./CodeBrowser/MenuBar";
import { CodeReadProvider } from "./CodeBrowser/CodeReadContext";
import CodeIndexDrawer from "./CodeBrowser/CodeIndexDrawer";
import CodeContent from "./CodeBrowser/CodeContent";
import Navigation from "./CodeBrowser/Navigation";
import ReadStatus from "./CodeBrowser/ReadStatus";
import ThemeProvider from "../theme/ThemeProvider";
import ContentReferences from "./CodeBrowser/ContentReferences";
import ElacAd from "./ProductBrowser/ElacAd";

const CodeIndex = (props) => {
  const { index, onClick, open, setOpen, currentPageId, code_id } = props;
  return (
    <CodeIndexDrawer
      open={open}
      index={index}
      code_id={code_id}
      onClose={() => setOpen(false)}
      onClick={onClick}
      currentPageId={currentPageId}
    />
  );
};

const CodeBrowser = (props) => {
  const { code_id } = props;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [content, setContent] = useState();
  const [command, setCommand] = useState("");

  const [indexOpen, setIndexOpen] = useState(false);
  const [referenceOpen, setReferenceOpen] = useState(false);

  const fetchIndex = () => {
    Promise.all([
      fetch(`/codes/${code_id}`),
      fetch(`/codes/${code_id}/last_seen`),
    ])
      .then((res) => Promise.all(res.map((r) => r.status == 200 && r.json())))
      .then(([code, last_seen]) => {
        if (last_seen) {
          setPage(code.contentIds.indexOf(parseInt(last_seen.content_id)));
        }
        setData(code);
      });
  };

  useEffect(() => {
    fetchIndex();
    window.addEventListener("keydown", handleKeyPress);
  }, []);

  // This is not entirely ideal as if forces 2x rerenders
  const handleKeyPress = (event) => {
    if (event.key == "ArrowLeft" || event.key == "ArrowRight")
      setCommand(event.key);
  };

  // Menu doesn't really understand pages, hence: use index
  const menuClick = (contentid) => {
    setPage(data.contentIds.indexOf(parseInt(contentid)));
  };

  const handleReferenceClick = () => {
    setReferenceOpen(!referenceOpen);
  };

  const prev =
    page == 0
      ? null
      : () => {
          if (page > 0) setPage(page - 1);
        };
  const next =
    page == data.contentIds?.length - 1
      ? null
      : () => {
          if (page < data.contentIds.length - 1) setPage(page + 1);
        };

  // Process any key presses
  if (command) {
    switch (command) {
      case "ArrowLeft":
        prev && prev();
        break;
      case "ArrowRight":
        next && next();
      default:
    }
    setCommand("");
  }

  const contentStyle = {
    marginLeft: indexOpen ? 150 : 50,
    transition: "margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)",
    maxWidth: 860,
  };

  return (
    <ThemeProvider>
      <MenuBar
        onIndexClick={() => setIndexOpen(!indexOpen)}
        title={data?.title}
      />
      <ElacAd open={referenceOpen} setOpen={setReferenceOpen} />
      <Container maxWidth="lg">
        <CodeReadProvider code_id={code_id}>
          <CodeIndex
            open={indexOpen}
            setOpen={setIndexOpen}
            code_id={code_id}
            index={data.index}
            currentPageId={data.contentIds ? data.contentIds[page] : 0}
            onClick={menuClick}
          />
          {data.contentIds && (
            <Grid container direction="column" spacing={3} style={contentStyle}>
              <Grid item>
                <Paper style={{ marginTop: 20 }}>
                  <CodeContent
                    codeId={code_id}
                    contentId={data.contentIds[page]}
                    content={content}
                    setContent={setContent}
                  />
                </Paper>
              </Grid>
              <Grid item>
                <ReadStatus
                  code_id={code_id}
                  content_id={data.contentIds[page]}
                />
              </Grid>
              <Grid item>
                <Navigation onPrev={prev} onNext={next} />
              </Grid>
              <Grid item>
                <ContentReferences
                  handleReferenceClick={handleReferenceClick}
                  links={content?.links}
                  urls={content?.url_links}
                />
              </Grid>
            </Grid>
          )}
        </CodeReadProvider>
      </Container>
    </ThemeProvider>
  );
};

export default CodeBrowser;
