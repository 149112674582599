import React, { useState } from "react";
import { Typography } from "@mui/material";
import LightBulbIcon from "../../icons/LightBulb";
import ElacAd from "./ElacAd";
import { YellowButton } from "../../lib/Buttons";
import Card from "./Card";

const ElacCard = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ElacAd open={open} setOpen={setOpen} />
      <Card
        icon={<LightBulbIcon />}
        actions={<YellowButton variant="contained">Read more</YellowButton>}
        {...props}
        cardstyle={{ backgroundColor: "#FDC30033" }}
        onClick={() => setOpen(!open)}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Discover how to embed and sustain Industry Codes effectively and efficiently in your Organisation
        </Typography>
        <Typography sx={{mt:1}}>
          FX Global Code, Global Precious Metals Code, UK Money Markets Code,
          ...
        </Typography>
      </Card>
    </>
  );
};

export default ElacCard;
