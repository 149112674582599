import { csrfToken } from "./meta";

const GET = (uri) => {
  return fetch(uri, {
    headers: {
      "X-CSRF-token": csrfToken(),
      "Content-Type": "application/json",
    },
  });
};

const PUT = (uri) => {
  return fetch(uri, {
    method: "PUT",
    headers: {
      "X-CSRF-token": csrfToken(),
      "Content-Type": "application/json",
    },
  });
};

const POST = (uri, params) => {
  return fetch(uri, {
    method: "POST",
    headers: {
      "X-CSRF-token": csrfToken(),
      "Content-Type": "application/json",
    },
    ...params,
  });
};

const PATCH = (uri, params) => {
  return fetch(uri, {
    method: "PATCH",
    headers: {
      "X-CSRF-token": csrfToken(),
      "Content-Type": "application/json",
    },
    ...params,
  });
};

const DELETE = (uri) => {
  return fetch(uri, {
    method: "DELETE",
    headers: { "X-CSRF-token": csrfToken() },
  });
};

export { GET, PUT, POST, PATCH, DELETE };
