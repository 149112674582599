import CRUD from "../lib/CRUD";

const Products = () => {
  const product_spec = [
    { id: "name", label: "Product" },
    { id: "uri", label: "URI" },
  ];

  return CRUD({
    spec: product_spec,
    namespace: "admin",
    model: "product",
    title: "Product",
  });
};

export default Products;
