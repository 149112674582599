import React from "react";

const GreenCheckIcon = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="code"
        transform="translate(-36.000000, -343.000000)"
        fill="#69AC83"
        fillRule="nonzero"
      >
        <g id="Group-6" transform="translate(20.000000, 135.000000)">
          <g id="Group-8" transform="translate(0.000000, 192.000000)">
            <g
              id="check_circle_FILL1_wght400_GRAD0_opsz48"
              transform="translate(16.000000, 16.000000)"
            >
              <path
                d="M6.82,11.64 L12.48,5.98 L11.56,5.08 L6.82,9.82 L4.42,7.42 L3.52,8.32 L6.82,11.64 Z M8,16 C6.90666667,16 5.87333333,15.79 4.9,15.37 C3.92666667,14.95 3.07666667,14.3766667 2.35,13.65 C1.62333333,12.9233333 1.05,12.0733333 0.63,11.1 C0.21,10.1266667 0,9.09333333 0,8 C0,6.89333333 0.21,5.85333333 0.63,4.88 C1.05,3.90666667 1.62333333,3.06 2.35,2.34 C3.07666667,1.62 3.92666667,1.05 4.9,0.63 C5.87333333,0.21 6.90666667,0 8,0 C9.10666667,0 10.1466667,0.21 11.12,0.63 C12.0933333,1.05 12.94,1.62 13.66,2.34 C14.38,3.06 14.95,3.90666667 15.37,4.88 C15.79,5.85333333 16,6.89333333 16,8 C16,9.09333333 15.79,10.1266667 15.37,11.1 C14.95,12.0733333 14.38,12.9233333 13.66,13.65 C12.94,14.3766667 12.0933333,14.95 11.12,15.37 C10.1466667,15.79 9.10666667,16 8,16 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default GreenCheckIcon;
