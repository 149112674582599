import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import ThemeProvider from "../../theme/ThemeProvider";
import MenuIcon from "../../icons/MenuIcon";
import Logo from "../../icons/Logo";
import CrossIcon from "../../icons/CrossIcon";
import InfoIcon from "../../icons/InfoIcon";
import CodeInfo from "./CodeInfo";

const MenuBar = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <ThemeProvider>
      <CodeInfo open={open} onClose={() => setOpen(false)} />
      <AppBar position="sticky">
        <Toolbar>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={props.onIndexClick}>
              <MenuIcon />
            </IconButton>
            <IconButton href="/">
              <Logo />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
            <Typography style={{ color: "white", textAlign: "center" }}>
              {props.title}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={() => setOpen(true)}>
              <InfoIcon />
            </IconButton>
            <IconButton href="/">
              <CrossIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider >
  );
};

MenuBar.propTypes = {
  onIndexClick: PropTypes.func.isRequired,
};

export default MenuBar;
