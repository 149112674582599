import React, { useState } from "react";

import {
  Box,
  Divider,
  Typography,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { ArrowRight, ArrowDropDown } from "@mui/icons-material";

const ContentLink = ({ link, handleReferenceClick }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)}>
        <ListItemText>
          <Typography sx={{ fontSize: 16, fontWeight: 700 }}>
            {link.code.title}
          </Typography>
        </ListItemText>
        {open ? <ArrowDropDown /> : <ArrowRight />}
      </ListItemButton>
      <Collapse in={open}>
        {link.content.map((content, index) => (
          <ListItemButton
            onClick={handleReferenceClick}
            key={index}
            sx={{ paddingLeft: 2 }}
            primaryTypographyProps={{ style: { lineHeight: 2 } }}
          >
            <ListItemText>{content.title}</ListItemText>
          </ListItemButton>
        ))}
      </Collapse>
      <Divider />
    </>
  );
};

const ContentLinks = ({ links, handleReferenceClick }) => {
  if (!links) return null;
  return (
    <List>
      {links.map((link, index) => (
        <ContentLink
          handleReferenceClick={handleReferenceClick}
          key={index}
          link={link}
        />
      ))}
    </List>
  );
};

const ContentURLs = ({ urls }) => {
  return null;
};

const ContentReferences = (props) => {
  const { links, urls, handleReferenceClick } = props;

  if (!links?.length && !urls?.length) return null;
  return (
    <Box sx={{ padding: 2 }}>
      <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
        Code References
      </Typography>
      <Divider />
      <ContentLinks links={links} handleReferenceClick={handleReferenceClick} />
      <ContentURLs urls={urls} />
    </Box>
  );
};

export default ContentReferences;
