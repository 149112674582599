import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  DialogContent,
  IconButton,
  Icon,
} from "@mui/material";
import pluralize from "pluralize";
import { PATCH, POST, DELETE } from "../lib/API";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const EditDialog = (props) => {
  const { onClose, onSave, open, spec, item, title } = props;

  const [values, setValues] = useState({});

  const onChange = (e) => {
    values[e.target.id] = e.target.value;
    setValues(values);
  };

  const handleSave = () => {
    onSave(item["id"] ? { ...values, ...{ id: item["id"] } } : values);
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Edit {title}</DialogTitle>
      <DialogContent style={{ paddingTop: 10 }}>
        {spec.map((line) => (
          <div key={line.id}>
            <TextField
              id={line.id}
              label={line.label}
              defaultValue={item[line.id]}
              variant="outlined"
              onChange={onChange}
            />
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

const CRUD = (props) => {
  const { spec, namespace, model, title, onClick, listItem, style } = props;

  const CustomListItem = listItem;

  const [list, setList] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentItem, setCurrentItem] = useState({});

  const prefix = namespace ? `${namespace}/` : "";

  const fetchList = () => {
    fetch(`/${prefix}${pluralize(model)}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setList(res);
      });
  };

  const saveItem = (item) => {
    if (item["id"]) {
      // update
      const id = item["id"];
      delete item["id"];
      PATCH(`/${prefix}${pluralize(model)}/${id}`, {
        body: JSON.stringify({ [model]: item }),
      }).then(fetchList());
    } else {
      // save
      POST(`/${prefix}${pluralize(model)}`, {
        body: JSON.stringify({ [model]: item }),
      }).then(fetchList());
    }
  };

  const deleteItem = (item) => {
    if (!window.confirm("Are you sure?")) return;
    DELETE(`/${prefix}${pluralize(model)}/${item.id}`).then(fetchList());
  };

  const editItem = (item) => {
    setCurrentItem(item);
    setOpenEdit(true);
  };

  useEffect(() => {
    fetchList();
  }, []);

  const openCreateItem = () => {
    setCurrentItem({});
    setOpenEdit(true);
  };

  const onClose = () => {
    setOpenEdit(false);
  };

  const onSave = (values) => {
    saveItem(values);
  };

  const Item = (props) => {
    const { data, onDelete, onEdit, onClick } = props;
    return (
      <ListItem
        secondaryAction={
          <>
            <IconButton onClick={onEdit}><EditIcon /></IconButton>
            <IconButton onClick={onDelete} ><DeleteIcon /></IconButton>
          </>
        }
      >
        <ListItemText onClick={() => onClick && onClick(data)}>
          {data.name}
        </ListItemText>
      </ListItem>
    );
  };

  return (
    <Paper
      elevation={0}
      style={style}
    >
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem secondaryAction={<AddIcon onClick={openCreateItem} />}>
          {pluralize(title)}
        </ListItem>
        <Divider />
        {list &&
          list.map((item) => {
            return (
              CustomListItem ? <CustomListItem key={item.id}
                data={item}
                onClick={onClick}
                onEdit={() => editItem(item)}
                onDelete={() => deleteItem(item)} />
                :
                <Item
                  key={item.id}
                  data={item}
                  onClick={onClick}
                  onEdit={() => editItem(item)}
                  onDelete={() => deleteItem(item)}
                />
            );
          })}
      </List>
      <EditDialog
        open={openEdit}
        onSave={onSave}
        onClose={onClose}
        spec={spec}
        item={currentItem}
        title={title}
      />
    </Paper>
  );
};

CRUD.propTypes = {
  spec: PropTypes.array.isRequired,
  namespace: PropTypes.string,
  model: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default CRUD;
