import React from "react";

import PropTypes from "prop-types";
import {
  Drawer,
  Divider,
  Box,
  Typography,
  Paper
} from "@mui/material";
import { YellowButton } from "../../lib/Buttons";

const Header = ({ title }) => (
  <Box style={{ width: "100%", paddingTop: 10, paddingBottom: 10 }}>
    <Typography variant="h2" sx={{ textAlign: "center" }}>{title}</Typography>
  </Box>
);

const Movie = () => {
  const data =
    '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/436507415?h=b00faf4fbb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="ACI FMA ELAC - Bring Codes of Conduct and Good Market Practices to Life"></iframe></div>     <script src="https://player.vimeo.com/api/player.js"></script>';
  return (
    <Paper sx={{ my: 1, mx: 20 }}>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Paper>
  );
};

const ElacAd = ({ open, setOpen }) => {
  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      anchor="right"
      PaperProps={{ sx: { width: 800, py: 1, px: 10 } }}
    >
      <Header title="Industry Codes Training and Adherance" />
      <Divider />
      <Movie />
      <Divider />
      <Typography sx={{ pt: 1, pb: 1, fontSize: 20, fontStyle: "italic", textAlign: "center" }}>
        FX Global Code - Global Precious Metals Code - the UK Money Market Code
      </Typography>
      <Typography sx={{ pt: 1, pb: 1, fontSize: 18, fontWeight: 600 }}>
        Are you responsible for training and adherence to Industry Codes of
        good market practices and looking for a sustainable solution to
        embed and sustain these Codes effectively and efficiently in your
        organisation?
      </Typography>
      <Box sx={{ px: 10, py: 2 }}>
        <YellowButton
          href="https://elac.acifma.com/requestAccount.html"
          target="_blank"
          style={{ fontSize: 18 }}
        >
          Find out how the ACI FMA ELAC Portal can help
        </YellowButton>
      </Box>

      <Typography sx={{ pt: 1, pb: 1, fontSize: 18, fontWeight: 600 }}>
        ELAC is the ACI FMA world-class E-Learning, Attestation and
        Certification (ELAC) Portal that brings Industry Codes to life:
      </Typography>
      <ul>
        <li>
          <Typography sx={{ fontSize: 16 }} >
            Allows 24/7 access to read and study Industry Codes such as FX Global Code, Global Precious Metals Code and the UK Money Market Code (amongst others);
          </Typography>
        </li>
        <li>
          <Typography sx={{ fontSize: 16 }}>
            It is a cost-effective, proportional programme to embed and sustain Industry Codes, regulations and market guidelines;
          </Typography>
        </li>
        <li>
          <Typography sx={{ fontSize: 16 }}>
            Includes industry-sourced “real life” scenarios that teach how good market practices can be applied in the daily roles of individual Market Participants;
          </Typography>
        </li>
        <li>
          <Typography sx={{ fontSize: 16 }}>
            Supplies new scenarios and questions which are added continually;
          </Typography>
        </li>
        <li>
          <Typography sx={{ fontSize: 16 }}>
            Provides continuing training and certification;
          </Typography>
        </li>
        <li>
          <Typography sx={{ fontSize: 16 }}>
            Consists of a reporting dashboard to demonstrate and communicate Code compliancy and Continuing Professional Development (CPD) measured across all departments in an organisation;
          </Typography>
        </li>
        <li>
          <Typography sx={{ fontSize: 16 }}>
            Subscription model - per user license fee.
          </Typography>
        </li>
      </ul>
      <Typography sx={{ pt: 1, pb: 1, fontSize: 16 }}>
        ELAC is actively used by Market Participants from all sectors of the market, from buy side to sell side (including central banks, large financial institutions and corporate treasuries, amongst others).
      </Typography>
      <Box style={{ minHeight: 80 }}></Box>
    </Drawer>
  );
};

ElacAd.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ElacAd;
