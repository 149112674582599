import React, { useState } from "react";

import { IconButton, Menu, MenuItem, Link } from "@mui/material";

const MenuButton = (props) => {
  const { icon, children } = props;

  const [showMenu, setShowMenu] = useState(false);

  const openMenu = (event) => {
    setShowMenu({ anchorEl: event.currentTarget });
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
      <IconButton onClick={(event) => openMenu(event)} color="inherit">
        {icon}
      </IconButton>
      {showMenu ? (
        <Menu
          anchorEl={showMenu.anchorEl}
          open={!!showMenu}
          onClose={closeMenu}
        >
          {children}
        </Menu>
      ) : null}
    </>
  );
};

export default MenuButton;
