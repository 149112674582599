import React from "react";

const LightBulb = () => (
  <svg
    width="64px"
    height="69px"
    viewBox="0 0 64 69"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient
        x1="9.64375097%"
        y1="35.2406181%"
        x2="101.943224%"
        y2="72.1837962%"
        id="linearGradient-1"
      >
        <stop stopColor="#D9B1EE" offset="0%"></stop>
        <stop stopColor="#814AA0" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="45.2113703%"
        x2="50%"
        y2="88.8057136%"
        id="linearGradient-2"
      >
        <stop stopColor="#5B3374" stopOpacity="0" offset="0%"></stop>
        <stop stopColor="#5B3374" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="89.6470357%"
        y1="75.1617432%"
        x2="52.0872029%"
        y2="51.4652565%"
        id="linearGradient-3"
      >
        <stop stopColor="#5B3374" stopOpacity="0" offset="0%"></stop>
        <stop stopColor="#5B3374" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="37.7903577%"
        y1="28.3418149%"
        x2="52.5678963%"
        y2="55.0028404%"
        id="linearGradient-4"
      >
        <stop stopColor="#E6EEFF" offset="0%"></stop>
        <stop stopColor="#BAC8FA" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="31.3679852%"
        y1="31.225733%"
        x2="53.0193689%"
        y2="53.3443938%"
        id="linearGradient-5"
      >
        <stop stopColor="#E6EEFF" offset="0%"></stop>
        <stop stopColor="#BAC8FA" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.2455964%"
        y1="51.1960237%"
        x2="51.1356991%"
        y2="47.7623589%"
        id="linearGradient-6"
      >
        <stop stopColor="#A7B7F1" stopOpacity="0" offset="0%"></stop>
        <stop stopColor="#A7B7F1" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="24.7728633%"
        y1="31.757924%"
        x2="90.2054817%"
        y2="84.0345241%"
        id="linearGradient-7"
      >
        <stop stopColor="#E6EEFF" offset="0%"></stop>
        <stop stopColor="#BAC8FA" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="21.2180348%"
        y1="31.5915531%"
        x2="84.3325837%"
        y2="81.8424549%"
        id="linearGradient-8"
      >
        <stop stopColor="#E6EEFF" offset="0%"></stop>
        <stop stopColor="#BAC8FA" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="17.0784807%"
        y1="-11.8077937%"
        x2="62.3921706%"
        y2="73.0600297%"
        id="linearGradient-9"
      >
        <stop stopColor="#A7B7F1" stopOpacity="0" offset="0%"></stop>
        <stop stopColor="#A7B7F1" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="-14.3767221%"
        y1="-37.5005934%"
        x2="154.382579%"
        y2="193.576074%"
        id="linearGradient-10"
      >
        <stop stopColor="#A7B7F1" stopOpacity="0" offset="0%"></stop>
        <stop stopColor="#A7B7F1" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="40.8322308%"
        y1="72.317897%"
        x2="65.347985%"
        y2="27.5387843%"
        id="linearGradient-11"
      >
        <stop stopColor="#A7B7F1" stopOpacity="0" offset="0%"></stop>
        <stop stopColor="#FFF5C6" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="63.7170876%"
        y1="73.8360156%"
        x2="30.1429627%"
        y2="28.2356946%"
        id="linearGradient-12"
      >
        <stop stopColor="#A7B7F1" stopOpacity="0" offset="0%"></stop>
        <stop stopColor="#FFF5C6" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="33.5368039%"
        y1="31.4287671%"
        x2="74.9350724%"
        y2="78.8743151%"
        id="linearGradient-13"
      >
        <stop stopColor="#FEE45A" offset="0%"></stop>
        <stop stopColor="#FEA613" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="25.3619565%"
        x2="50%"
        y2="83.5543478%"
        id="linearGradient-14"
      >
        <stop stopColor="#FE7701" stopOpacity="0" offset="0%"></stop>
        <stop stopColor="#F87204" stopOpacity="0.185" offset="18.54%"></stop>
        <stop stopColor="#E7630C" stopOpacity="0.431" offset="43.14%"></stop>
        <stop stopColor="#CB4B19" stopOpacity="0.711" offset="71.14%"></stop>
        <stop stopColor="#A72B2B" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="28.3786667%"
        x2="50%"
        y2="69.032%"
        id="linearGradient-15"
      >
        <stop stopColor="#5A5A5A" offset="0%"></stop>
        <stop stopColor="#444444" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="28.3783784%"
        x2="50%"
        y2="69.0324324%"
        id="linearGradient-16"
      >
        <stop stopColor="#B4D2E2" offset="0%"></stop>
        <stop stopColor="#7BACDF" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="70.1950345%"
        x2="50%"
        y2="-41.0300866%"
        id="linearGradient-17"
      >
        <stop stopColor="#FFF5C6" stopOpacity="0" offset="0%"></stop>
        <stop stopColor="#FFF5C6" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="49.9992353%"
        y1="5.19715498%"
        x2="49.9992353%"
        y2="144.063514%"
        id="linearGradient-18"
      >
        <stop stopColor="#FEE45A" offset="0%"></stop>
        <stop stopColor="#FEA613" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="31.439698%"
        y1="29.3149463%"
        x2="88.0225772%"
        y2="85.8978255%"
        id="linearGradient-19"
      >
        <stop stopColor="#FEE45A" offset="0%"></stop>
        <stop stopColor="#FEA613" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="70.101067%"
        y1="30.9770614%"
        x2="13.5181878%"
        y2="87.5599407%"
        id="linearGradient-20"
      >
        <stop stopColor="#FEE45A" offset="0%"></stop>
        <stop stopColor="#FEA613" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="50.0007647%"
        y1="5.19715498%"
        x2="50.0007647%"
        y2="144.063514%"
        id="linearGradient-21"
      >
        <stop stopColor="#FEE45A" offset="0%"></stop>
        <stop stopColor="#FEA613" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="68.6673702%"
        y1="29.3149463%"
        x2="12.084491%"
        y2="85.8978255%"
        id="linearGradient-22"
      >
        <stop stopColor="#FEE45A" offset="0%"></stop>
        <stop stopColor="#FEA613" offset="100%"></stop>
      </linearGradient>
      <linearGradient
        x1="30.0057989%"
        y1="30.9770614%"
        x2="86.5882966%"
        y2="87.5599407%"
        id="linearGradient-23"
      >
        <stop stopColor="#FEE45A" offset="0%"></stop>
        <stop stopColor="#FEA613" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="overview"
        transform="translate(-584.000000, -307.000000)"
        fillRule="nonzero"
      >
        <g id="button/purple" transform="translate(513.000000, 307.000000)">
          <g id="light-bulb" transform="translate(71.000000, 0.000000)">
            <g id="Group" transform="translate(6.453730, 32.101363)">
              <path
                d="M49.1178863,3.60887972 C41.2608155,0.664646304 33.3935258,0.936924364 25.5462705,4.41657074 L25.5462705,5.68236119 C25.5462705,5.63274607 25.5462705,5.21081592 25.5462705,4.41657074 C17.6916199,0.933562906 9.82433023,0.667469929 1.97452015,3.60887972 C0.784564187,4.05474345 0.000134458301,5.19804238 0.000134458301,6.46867333 L0.000134458301,34.4813124 C0.000134458301,35.6252836 1.13724831,36.4281342 2.21143568,36.0344402 C9.31271639,33.4318654 16.4281152,33.4544544 23.5351777,36.1085268 C24.8333726,36.5932489 26.2595718,36.5932489 27.5577666,36.1083923 C34.6587784,33.4564712 41.7741773,33.4298485 48.8813742,36.0345747 C49.9554271,36.4282686 51.0925409,35.6252836 51.0925409,34.4813124 C51.0925409,25.3593923 51.0925409,15.5905934 51.0925409,6.46867333 C51.0925409,5.19804238 50.3078423,4.05474345 49.1178863,3.60887972 L49.1178863,3.60887972 Z"
                id="Path"
                fill="url(#linearGradient-1)"
              ></path>
              <path
                d="M0,34.4814469 C0,35.6254181 1.13724831,36.4282686 2.21143568,36.0345747 C9.31271639,33.4319998 16.4279808,33.4545888 23.5351777,36.1086612 C24.8333726,36.5935179 26.2595718,36.5933834 27.5577666,36.1085268 C34.6587784,33.4566057 41.7740428,33.429983 48.8813742,36.0347092 C49.9554271,36.4284031 51.0925409,35.6254181 51.0925409,34.4814469 C51.0925409,29.9066376 51.0925409,25.1692683 51.0925409,20.4323024 L0,20.4323024 C0,25.1692683 0,29.9066376 0,34.4814469 Z"
                id="Path"
                fill="url(#linearGradient-2)"
              ></path>
              <path
                d="M51.0920031,6.46840441 L51.0920031,34.4814469 C51.0920031,35.625687 49.9558304,36.4284031 48.8815086,36.0344402 C41.7740428,33.429983 34.6585095,33.4568746 27.5577666,36.1083923 C26.2588995,36.5937868 24.8336415,36.5937868 23.5347743,36.1083923 C17.4693603,33.8441145 11.3972234,33.4958675 5.33315406,35.0609621 L3.16971999,32.8975281 L3.16971999,3.18896645 C10.6227436,0.704177045 18.0892131,1.11024111 25.5462705,4.41657074 C33.3932569,0.936789906 41.2604121,0.665184137 49.1181552,3.60847635 C50.3081112,4.05487791 51.0920031,5.19777347 51.0920031,6.46840441 Z"
                id="Path"
                fill="url(#linearGradient-3)"
              ></path>
              <g transform="translate(2.553901, -0.000000)" id="Path">
                <g transform="translate(0.000538, 25.915440)">
                  <path
                    d="M22.9918317,2.54657765 L22.9918317,7.99859284 C15.9942183,4.89637092 8.98396585,4.62933673 1.98957948,7.19264979 C1.02242092,7.54667849 0,6.82450296 0,5.79562804 L0,0.343612844 C0,1.37235331 1.02242092,2.09452884 1.98957948,1.74063459 C8.98396585,-0.822544001 15.9942183,-0.555509815 22.9918317,2.54657765 L22.9918317,2.54657765 Z"
                    fill="url(#linearGradient-4)"
                  ></path>
                  <path
                    d="M45.9836633,0.343747303 L45.9836633,5.7957625 C45.9836633,6.82450296 44.9612424,7.54829199 43.9940838,7.19278425 C36.9933779,4.62799215 29.9831255,4.89825334 22.9918317,7.9987273 L22.9918317,2.54671211 C29.9831255,-0.553896316 36.9933779,-0.824157501 43.9940838,1.74076905 C44.9612424,2.0962768 45.9836633,1.37248776 45.9836633,0.343747303 L45.9836633,0.343747303 Z"
                    fill="url(#linearGradient-5)"
                  ></path>
                </g>
                <path
                  d="M43.9946217,31.6068628 C36.9939158,29.0420708 29.9836633,29.3121975 22.9923695,32.4128059 C15.9947561,29.310584 8.98450368,29.0435498 1.99011731,31.6068628 C1.02295875,31.9608916 0.000537833204,31.238716 0.000537833204,30.2098411 L0.000537833204,31.711068 C0.000537833204,32.7398085 1.02295875,33.461984 1.99011731,33.1080898 C8.98450368,30.5447767 15.9947561,30.8119454 22.9923695,33.9140328 C29.9836633,30.8134244 36.9939158,30.5432977 43.9946217,33.1080898 C44.9617802,33.4635975 45.9842011,32.739943 45.9842011,31.711068 L45.9842011,30.2098411 C45.9842011,31.238716 44.9617802,31.9623706 43.9946217,31.6068628 L43.9946217,31.6068628 Z"
                  fill="url(#linearGradient-6)"
                ></path>
                <g>
                  <path
                    d="M0,4.3941162 L0,29.0241878 C0,30.0538695 1.02349659,30.7764484 1.99025177,30.4221508 C8.98450368,27.8587032 15.9939494,28.1250651 22.9923695,31.2280938 C22.9923695,21.6677052 22.9923695,12.1073166 22.9923695,2.54692806 C15.9229554,-0.587832771 8.8422468,-0.827437463 1.77713537,1.82004649 C0.706174997,2.22140451 0,3.25041389 0,4.3941162 Z"
                    fill="url(#linearGradient-7)"
                  ></path>
                  <path
                    d="M22.9923695,2.54692806 L22.9923695,31.2280938 C29.9842011,28.1276198 36.9936468,27.8565519 43.9944872,30.4222852 C44.9612424,30.7765828 45.9846045,30.0538695 45.9846045,29.0243223 C45.9846045,20.8142984 45.9846045,12.6042745 45.9846045,4.3941162 C45.9846045,3.25041389 45.2784295,2.22140451 44.2074692,1.82004649 C37.1359037,-0.829857713 30.0551951,-0.58474023 22.9923695,2.54692806 L22.9923695,2.54692806 Z"
                    fill="url(#linearGradient-8)"
                  ></path>
                  <path
                    d="M43.6081885,16.3010711 C42.1372147,15.8752416 40.6658375,15.5748618 39.1940569,15.3995281 C38.4178292,15.3070208 37.7326297,15.9054947 37.7326297,16.6871008 L37.7326297,25.8035081 C37.7326297,26.4659842 38.23147,27.0218348 38.8901812,27.0925598 C40.2604457,27.2395228 41.6304414,27.4945902 43.0000336,27.8588377 C43.7115869,28.048155 44.4107701,27.5195994 44.4107701,26.7833058 L44.4107701,17.373376 C44.4107701,16.8777627 44.0844398,16.4390253 43.6081885,16.3010711 L43.6081885,16.3010711 Z"
                    fill="url(#linearGradient-9)"
                  ></path>
                  <path
                    d="M4.58919628,17.030104 C7.80463209,16.1744113 11.0226226,15.9167892 14.2404787,16.2569687 C14.8987865,16.3266181 15.4718478,15.8100293 15.4718478,15.1480911 L15.4718478,5.66636065 C15.4718478,5.10284591 15.0523379,4.62296423 14.4927224,4.55828979 C10.9894114,4.15383922 7.48583146,4.4579839 3.98561296,5.47099274 C3.50882383,5.60894695 3.1812834,6.04754993 3.1812834,6.54383552 L3.1812834,15.9537653 C3.18114895,16.6889833 3.87871861,17.2191523 4.58919628,17.030104 L4.58919628,17.030104 Z"
                    fill="url(#linearGradient-10)"
                  ></path>
                  <g transform="translate(2.071233, 3.777647)" fill="#7BACDF">
                    <g transform="translate(0.000000, 16.330362)">
                      <path d="M0.966852694,3.30765434 C0.559712958,3.30765434 0.180809466,3.04841873 0.0478302058,2.64020333 C-0.117553505,2.13248879 0.159699512,1.58658808 0.667414057,1.42106991 C6.33953749,-0.428000642 12.092067,-0.472909715 17.7654005,1.28822511 C18.2754009,1.44661699 18.5604525,1.98834949 18.4021951,2.49848428 C18.2439376,3.00835016 17.7022051,3.29353622 17.1920703,3.1352788 C11.9088003,1.4952909 6.55077148,1.53724189 1.26696362,3.25978718 C1.16719556,3.29219163 1.06608292,3.30765434 0.966852694,3.30765434 L0.966852694,3.30765434 Z"></path>
                      <path d="M0.966852694,7.34731954 C0.559712958,7.34731954 0.180809466,7.08808393 0.0478302058,6.67986853 C-0.117553505,6.17215398 0.159699512,5.62625328 0.667414057,5.46073511 C6.33926857,3.61139564 12.0917981,3.56675548 17.7654005,5.32789031 C18.2754009,5.48628219 18.5604525,6.02801469 18.4021951,6.53801502 C18.2439376,7.0478809 17.7022051,7.33306696 17.1920703,7.17480954 C11.9083969,5.53455272 6.55050256,5.57663817 1.26696362,7.29931792 C1.16719556,7.33185683 1.06608292,7.34731954 0.966852694,7.34731954 Z"></path>
                    </g>
                    <g transform="translate(23.893439, 0.000000)">
                      <g transform="translate(0.000000, 16.331683)">
                        <path d="M10.3067998,0.967296763 L10.3067998,0.968506888 C10.3067998,1.50472659 9.87075154,1.93364857 9.33453183,1.93553099 C6.63904627,1.94494307 3.94356071,2.38583184 1.26663039,3.2581973 C1.16740016,3.29046729 1.06682535,3.30592999 0.967460669,3.30592999 C0.560052016,3.30592999 0.181014066,3.04682885 0.0481692641,2.6394202 C-0.118155654,2.13143673 0.160307487,1.58607386 0.666946366,1.41974895 C3.54596751,0.481767838 6.44596415,0.00780232609 9.34515404,0 C9.87733999,-0.00147529669 10.3067998,0.435379724 10.3067998,0.967296763 L10.3067998,0.967296763 Z"></path>
                        <path d="M10.3067998,5.00561738 C10.3067998,5.54156817 9.87102045,5.97049015 9.33493521,5.97264148 C6.64079423,5.98339814 3.94342625,6.42630379 1.26663039,7.29866925 C1.16740016,7.33093924 1.06682535,7.34640194 0.967460669,7.34640194 C0.560052016,7.34640194 0.181014066,7.0873008 0.0481692641,6.67854756 C-0.118155654,6.1705641 0.160307487,5.62520123 0.666946366,5.45887631 C3.54354726,4.52210533 6.44529186,4.04719861 9.34475066,4.03818545 C9.87707108,4.0365764 10.3067998,4.47343142 10.3067998,5.00561738 L10.3067998,5.00561738 Z"></path>
                      </g>
                      <g transform="translate(0.000000, 8.165267)">
                        <path d="M0.967326211,3.30749945 C0.560052016,3.30749945 0.181148524,3.04812938 0.0481692641,2.63991398 C-0.117214446,2.13233389 0.160038571,1.58643319 0.667887574,1.42091502 C6.33987655,-0.428155534 12.092406,-0.472661232 17.7657396,1.28807022 C18.2757399,1.4464621 18.5607915,1.9881946 18.4025341,2.49819493 C18.2442767,3.00819527 17.7025442,3.29311241 17.1924094,3.13498945 C11.9092738,1.49513601 6.551245,1.537087 1.26716822,3.25949783 C1.16766908,3.2921712 1.06655644,3.30749945 0.967326211,3.30749945 L0.967326211,3.30749945 Z"></path>
                        <path d="M10.3067998,5.0082625 L10.3067998,5.00947262 C10.3067998,5.54569233 9.87075154,5.97461431 9.33453183,5.97649672 C6.63904627,5.9859088 3.94356071,6.42679757 1.26663039,7.29916303 C1.16740016,7.33143302 1.06682535,7.34689573 0.967460669,7.34689573 C0.560052016,7.34689573 0.181014066,7.08779458 0.0481692641,6.68038593 C-0.118155654,6.17240247 0.160307487,5.6270396 0.666946366,5.46071468 C3.54596751,4.52273357 6.44596415,4.04890252 9.34515404,4.04096502 C9.87733999,4.03935598 10.3067998,4.476211 10.3067998,5.0082625 L10.3067998,5.0082625 Z"></path>
                      </g>
                      <g transform="translate(0.000340, 0.000000)">
                        <path d="M0.966986139,3.30765186 C0.559711945,3.30765186 0.180808453,3.04841625 0.047829193,2.64006639 C-0.117554517,2.13248631 0.1596985,1.5865856 0.667547503,1.42106744 C6.33926756,-0.42813758 12.092066,-0.472777736 17.7653995,1.28822263 C18.2753999,1.44661451 18.5604515,1.98834701 18.402194,2.49834734 C18.2439366,3.00821322 17.702742,3.29313036 17.1920693,3.13514186 C11.9087993,1.49515396 6.55077047,1.53697049 1.26682815,3.25965024 C1.16732901,3.29218915 1.06621637,3.30765186 0.966986139,3.30765186 L0.966986139,3.30765186 Z"></path>
                        <path d="M0.966986139,7.34731706 C0.559711945,7.34731706 0.180808453,7.08808145 0.047829193,6.67973159 C-0.117554517,6.17215151 0.1596985,5.6262508 0.667547503,5.46073263 C4.55581265,4.19319423 8.50283608,3.76857491 12.4006478,4.19924485 C12.9313547,4.25786867 13.314023,4.73586793 13.2555337,5.26657485 C13.1967754,5.79728176 12.7213308,6.17995009 12.1882037,6.12146073 C8.56616594,5.72104391 4.89195841,6.11729252 1.26696261,7.29931544 C1.16732901,7.33185435 1.06621637,7.34731706 0.966986139,7.34731706 L0.966986139,7.34731706 Z"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <path
              d="M9.00763051,36.4954788 L9.00763051,61.1255504 C9.00763051,62.1552321 10.0311271,62.877811 10.9978823,62.5235134 C17.9921342,59.9600659 25.0015799,60.2264278 32,63.3294565 C32,53.7690679 32,44.2086793 32,34.6482907 C24.9305859,31.5135299 17.8498773,31.2739252 10.7847659,33.9214091 C9.71380551,34.3227672 9.00763051,35.3517765 9.00763051,36.4954788 Z"
              id="Path"
              fill="url(#linearGradient-11)"
            ></path>
            <path
              d="M32,34.6482907 L32,63.3294565 C38.9918317,60.2289825 46.0012774,59.9579146 53.0021177,62.5236479 C53.9688729,62.8779455 54.992235,62.1552321 54.992235,61.1256849 C54.992235,52.915661 54.992235,44.7056372 54.992235,36.4954788 C54.992235,35.3517765 54.28606,34.3227672 53.2150997,33.9214091 C46.1435342,31.2715049 39.0628256,31.5166224 32,34.6482907 L32,34.6482907 Z"
              id="Path"
              fill="url(#linearGradient-12)"
            ></path>
            <g id="Group" transform="translate(14.871158, 0.000000)">
              <path
                d="M34.2576195,17.1287774 C34.2576195,7.6688292 26.5889247,0 17.1289765,0 C7.6896004,0 0.0451081464,7.60617164 0,17.0455477 C-0.0240034204,22.1216175 2.16027168,26.6875525 5.64744772,29.8391206 C8.30703292,32.2426972 9.80086464,35.6768967 9.80086464,39.2618239 L9.80086464,39.2618239 L24.4568195,39.2618239 C24.4568195,35.6412653 26.0145189,32.2136542 28.6860709,29.7701435 C32.1100515,26.6384752 34.2576195,22.13466 34.2576195,17.1287774 Z"
                id="Path"
                fill="url(#linearGradient-13)"
              ></path>
              <path
                d="M3.05388155,26.8916602 C3.80415887,27.970957 4.67491083,28.9601667 5.64744772,29.8391206 C8.30703292,32.2426972 9.80086464,35.6768967 9.80086464,39.2618239 L24.4568195,39.2618239 C24.4568195,35.6412653 26.0145189,32.2136542 28.6860709,29.7701435 C29.6279513,28.9086692 30.4726183,27.9427208 31.2032647,26.8916602 L3.05388155,26.8916602 Z"
                id="Path"
                fill="url(#linearGradient-14)"
              ></path>
              <g transform="translate(8.523511, 37.244949)">
                <circle
                  id="Oval"
                  fill="url(#linearGradient-15)"
                  cx="8.60533127"
                  cy="9.81545598"
                  r="5.04218629"
                ></circle>
                <path
                  d="M14.723184,9.94991428 L2.48747857,9.94991428 C1.11371811,9.94991428 0,8.83619617 0,7.46243571 C0,6.08867525 1.11371811,4.97495714 2.48747857,4.97495714 L14.723184,4.97495714 C16.0969444,4.97495714 17.2106625,6.08867525 17.2106625,7.46243571 C17.2106625,8.83619617 16.0969444,9.94991428 14.723184,9.94991428 Z"
                  id="Path"
                  fill="url(#linearGradient-16)"
                ></path>
                <path
                  d="M14.723184,4.97495714 L2.48747857,4.97495714 C1.11371811,4.97495714 0,3.86123903 0,2.48747857 C0,1.11371811 1.11371811,0 2.48747857,0 L14.723184,0 C16.0969444,0 17.2106625,1.11371811 17.2106625,2.48747857 C17.2106625,3.86123903 16.0969444,4.97495714 14.723184,4.97495714 Z"
                  id="Path"
                  fill="url(#linearGradient-16)"
                ></path>
              </g>
            </g>
            <circle
              id="Oval"
              fill="url(#linearGradient-17)"
              cx="32"
              cy="24.9644694"
              r="10.4307372"
            ></circle>
            <g id="Group" transform="translate(0.000000, 3.361424)">
              <g transform="translate(53.108844, 0.000000)" id="Path">
                <path
                  d="M2.09959999,12.4011227 C2.09959999,11.2108978 3.06447276,10.2460251 4.25469764,10.2460251 L8.73605835,10.2460251 C9.92628324,10.2460251 10.891156,11.2108978 10.891156,12.4011227 C10.891156,13.5913476 9.92628324,14.5562204 8.73605835,14.5562204 L4.25469764,14.5562204 C3.06447276,14.5560859 2.09959999,13.5912132 2.09959999,12.4011227 L2.09959999,12.4011227 Z"
                  fill="url(#linearGradient-18)"
                ></path>
                <g>
                  <path
                    d="M0.63118088,6.84772597 C-0.210393627,6.00615147 -0.210393627,4.64166863 0.63118088,3.79995966 L3.79995966,0.63118088 C4.64153417,-0.210393627 6.00601701,-0.210393627 6.84772597,0.63118088 C7.68930048,1.47275539 7.68930048,2.83723823 6.84772597,3.67894719 L3.67894719,6.84772597 C2.83723823,7.68930048 1.47275539,7.68930048 0.63118088,6.84772597 L0.63118088,6.84772597 Z"
                    fill="url(#linearGradient-19)"
                  ></path>
                  <path
                    d="M0.63118088,17.954385 C1.47275539,17.1128105 2.83723823,17.1128105 3.67894719,17.954385 L6.84772597,21.1231638 C7.68930048,21.9647383 7.68930048,23.3292212 6.84772597,24.1709301 C6.00615147,25.0125046 4.64166863,25.0125046 3.79995966,24.1709301 L0.63118088,21.0021513 C-0.210393627,20.1605768 -0.210393627,18.7959595 0.63118088,17.954385 L0.63118088,17.954385 Z"
                    fill="url(#linearGradient-20)"
                  ></path>
                </g>
              </g>
              <g id="Path">
                <path
                  d="M8.79155602,12.4011227 C8.79155602,11.2108978 7.82668325,10.2460251 6.63645837,10.2460251 L2.15509765,10.2460251 C0.964872769,10.2460251 0,11.2108978 0,12.4011227 C0,13.5913476 0.964872769,14.5562204 2.15509765,14.5562204 L6.63645837,14.5562204 C7.82668325,14.5560859 8.79155602,13.5912132 8.79155602,12.4011227 L8.79155602,12.4011227 Z"
                  fill="url(#linearGradient-21)"
                ></path>
                <g transform="translate(3.412249, 0.000000)">
                  <path
                    d="M6.84772597,6.84772597 C7.68930048,6.00615147 7.68930048,4.64166863 6.84772597,3.79995966 L3.67894719,0.63118088 C2.83737268,-0.210393627 1.47288985,-0.210393627 0.63118088,0.63118088 C-0.210393627,1.47275539 -0.210393627,2.83723823 0.63118088,3.67894719 L3.79995966,6.84772597 C4.64166863,7.68930048 6.00615147,7.68930048 6.84772597,6.84772597 Z"
                    fill="url(#linearGradient-22)"
                  ></path>
                  <path
                    d="M6.84772597,17.954385 C6.00615147,17.1128105 4.64166863,17.1128105 3.79995966,17.954385 L0.63118088,21.1231638 C-0.210393627,21.9647383 -0.210393627,23.3292212 0.63118088,24.1709301 C1.47275539,25.0125046 2.83723823,25.0125046 3.67894719,24.1709301 L6.84772597,21.0021513 C7.68943494,20.1605768 7.68930048,18.7959595 6.84772597,17.954385 Z"
                    fill="url(#linearGradient-23)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LightBulb;
