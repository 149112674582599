import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {Box, FormControl, InputLabel, Select, MenuItem, } from "@mui/material";
import {GET} from "../lib/API";

const AssocationSelect = ({ association, setAssociation, hasAdmin }) => {
  const [associations, setAssociations] = useState([]);

  const getAssociations = () => {
    GET(`/associations${hasAdmin ? "?has_admins=true" : ""}`)
      .then((res) => res.json())
      .then((res) => setAssociations(res));
  };

  useEffect(() => {
    getAssociations();
  }, []);

  const handleChange = (event) => {
    setAssociation(event.target.value);
  };

  if (associations.length == 0) return null;

  return (
    <Box sx={{ paddingTop: 2 }}>
      <FormControl fullWidth>
        <InputLabel>National Association</InputLabel>
        <Select
          value={association}
          label="National Association"
          onChange={handleChange}
        >
          <MenuItem key="00" value={"Not a member"}>Not a member</MenuItem>
          <MenuItem key="01" value={"Associations not listed"}>Association not Listed</MenuItem>
          {associations.map((assoc) => (
            <MenuItem key={assoc.id} value={assoc.id}>
              {assoc.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

AssocationSelect.propTypes = {
  association: PropTypes.any.isRequired,
  setAssociation: PropTypes.func.isRequired,
  hasAdmin: PropTypes.bool
}

export default AssocationSelect;