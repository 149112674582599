import React, { useState, useContext } from "react";
import {
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Typography,
} from "@mui/material";
import { ArrowRight, ArrowDropDown } from "@mui/icons-material";
import GreenCheckIcon from "../../icons/GreenCheckIcon";
import { CodeReadContext } from "./CodeReadContext";

const padding = 15;

const CodeItemSection = (props) => {
  const { item, indent, onClick, currentPageId } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText
          primary={item.title}
          primaryTypographyProps={{ variant: "body2" }}
          style={{ paddingLeft: padding * indent }}
        />
        {open ? <ArrowDropDown /> : <ArrowRight />}
      </ListItemButton>
      <Collapse in={open}>
        {item.index?.length && (
          <CodeItems
            openParent={() => setOpen(true)}
            list={item.index}
            indent={indent + 1}
            onClick={onClick}
            currentPageId={currentPageId}
          />
        )}
      </Collapse>
    </>
  );
};

const CodeItems = (props) => {
  const { list, indent, onClick, currentPageId, openParent } = props;

  const ManageParent = ({ selected }) => {
    if (selected && openParent) openParent();
    return null;
  };

  const { reads } = useContext(CodeReadContext);

  return (
    list &&
    list.map((i) =>
      i.type == "section" ? (
        <CodeItemSection
          key={i.id}
          item={i}
          indent={indent}
          onClick={onClick}
          currentPageId={currentPageId}
        />
      ) : (
        <ListItemButton
          selected={i.id == currentPageId}
          key={i.id}
          onClick={() => onClick(i.id)}
        >
          <ManageParent selected={i.id == currentPageId} />
          <ListItemText
            key={i.id}
            primary={i.title}
            primaryTypographyProps={{ variant: "body2" }}
            style={{ paddingLeft: padding * indent }}
          />
          {reads[i.id] && (
            <Box style={{ width: 16, paddingLeft: 4 }}>
              <GreenCheckIcon />
            </Box>
          )}
        </ListItemButton>
      )
    )
  );
};

const CodeIndexDialog = (props) => {
  const { open, onClose, index, currentPageId, onClick } = props;

  return (
    <Drawer
      open={open}
      onClose={onClose}
      variant="persistent"
      style={{ width: 300 }}
      PaperProps={{ style: { backgroundColor: "#F8F8FC" } }}
    >
      <Box style={{ padding: 10 }}>
        <Typography
          variant="body2"
          sx={{
            pt: 1,
            pl: 2,
            color: "#AEACCF",
            textTransform: "uppercase",
            fontWeight: 700,
          }}
        >
          {" "}
          Code Index
        </Typography>
        <List>
          <CodeItems
            key="top"
            list={index}
            indent={0}
            onClick={onClick}
            currentPageId={currentPageId}
          />
        </List>
      </Box>
    </Drawer>
  );
};

export default CodeIndexDialog;
