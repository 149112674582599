import React from "react";

const MenuIcon = () => (
  <svg
    width="22px"
    height="12px"
    viewBox="0 0 22 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="code"
        transform="translate(-21.000000, -26.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(20.000000, 20.000000)">
          <g
            id="list_FILL1_wght400_GRAD0_opsz48"
            transform="translate(1.200000, 6.000000)"
          >
            <path
              d="M0.9,12 C0.64,12 0.425,11.915 0.255,11.745 C0.085,11.575 0,11.36 0,11.1 C0,10.84 0.085,10.625 0.255,10.455 C0.425,10.285 0.64,10.2 0.9,10.2 C1.16,10.2 1.375,10.285 1.545,10.455 C1.715,10.625 1.8,10.84 1.8,11.1 C1.8,11.36 1.715,11.575 1.545,11.745 C1.375,11.915 1.16,12 0.9,12 Z M0.9,6.9 C0.64,6.9 0.425,6.815 0.255,6.645 C0.085,6.475 0,6.26 0,6 C0,5.74 0.085,5.525 0.255,5.355 C0.425,5.185 0.64,5.1 0.9,5.1 C1.16,5.1 1.375,5.185 1.545,5.355 C1.715,5.525 1.8,5.74 1.8,6 C1.8,6.26 1.715,6.475 1.545,6.645 C1.375,6.815 1.16,6.9 0.9,6.9 L0.9,6.9 Z M0.9,1.8 C0.64,1.8 0.425,1.715 0.255,1.545 C0.085,1.375 0,1.16 0,0.9 C0,0.64 0.085,0.425 0.255,0.255 C0.425,0.085 0.64,0 0.9,0 C1.16,0 1.375,0.085 1.545,0.255 C1.715,0.425 1.8,0.64 1.8,0.9 C1.8,1.16 1.715,1.375 1.545,1.545 C1.375,1.715 1.16,1.8 0.9,1.8 Z M5.1,12 L5.1,10.2 L21.6,10.2 L21.6,12 L5.1,12 Z M5.1,6.9 L5.1,5.1 L21.6,5.1 L21.6,6.9 L5.1,6.9 Z M5.1,1.8 L5.1,0 L21.6,0 L21.6,1.8 L5.1,1.8 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default MenuIcon;
