import React from "react";

import { Box, Grid, Typography, Avatar } from "@mui/material";
import { VoucherConsumer } from "./VoucherContext";

const TextBall = ({ children }) => {
  return (
    <Box>
      <Avatar
        sx={{
          margin: "auto",
          width: 120,
          height: 120,
          backgroundColor: "#F8F8FC",
          color: "#2C2A56",
        }}
      >
        <Typography variant="h5">{children}</Typography>
      </Avatar>
    </Box>
  );
};

const Stats = () => {
  return (
    <VoucherConsumer>
      {({ association, consumed, outstanding, available_vouchers }) => (
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <TextBall>{association?.member_count}</TextBall>
              <Typography sx={{ marginTop: 2, textAlign: "center" }}>
                Allocated Vouchers
              </Typography>
            </Grid>
            <Grid item>
              <TextBall>{consumed?.length}</TextBall>
              <Typography sx={{ marginTop: 2, textAlign: "center" }}>
                Accepted Vouchers
              </Typography>
            </Grid>
            <Grid item>
              <TextBall>{outstanding?.length}</TextBall>
              <Typography sx={{ marginTop: 2, textAlign: "center" }}>
                Sent Vouchers
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={{textAlign:"center", pt: 2}}>You have <b>{available_vouchers()}</b> available Vouchers</Typography>
        </Box>
      )}
    </VoucherConsumer>
  );
};

export default Stats;
