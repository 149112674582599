import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { GET, PUT } from "../lib/API";
import ThemeProvider from "../theme/ThemeProvider";


const VoucherConsumer = () => {
  const [open, setOpen] = useState(false);
  const [voucher, setVoucher] = useState();

  useEffect(() => {
    GET("/vouchers")
      .then((res) => res.json())
      .then((res) => {
        setVoucher(res);
        setOpen(true);
      })
      .catch(() => { }); // Ignore error
  }, [setVoucher, setOpen]);

  const reject = () => {
    PUT(`/vouchers/${voucher.token}/reject`).then(() => {
      setOpen(false);
      setVoucher(null);
    });
  };

  const accept = () => {
    PUT(`/vouchers/${voucher.token}/consume`)
      .then(() => {
        setOpen(false);
        setVoucher(null);
      })
      .finally(() => (window.location = "/"));
  };

  return (
    <ThemeProvider>
      <Dialog open={open}>
        <DialogTitle>Activation ACI FMA Handbook Voucher</DialogTitle>
        <DialogContent>
          <Typography>
            You can now access the "{voucher?.product.name}" on myACIFMA.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ pb: 2 }}>
          <Button onClick={accept}>OK</Button>
          <Button style={{ display: 'none' }} onClick={reject}>Reject</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default VoucherConsumer;
