import React, { useState, useContext } from "react";

import {
  Box,
  Tab,
  Tabs,
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copyToClipboard from "../../lib/copyToClickboard";
import { VoucherContext } from "./VoucherContext";
import BulkEmailDialog from "./BulkEmailDialog";

const EmailButton = () => {
  const [email, setEmail] = useState("");

  const { email_voucher, available_vouchers } = useContext(VoucherContext);

  const sendVoucher = () => {
    email_voucher(email)
      .then(() => {
        alert(`A Voucher has been emailed to ${email}`);
        setEmail("");
      })
      .catch((error) => {
        alert(`Something went wrong: ${error}`);
      });
  };

  const onChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography>Send a Voucher code by email</Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            label="Email address"
            value={email}
            onChange={onChange}
          />
        </Grid>
        <Grid item>
          <Button disabled={available_vouchers() <= 0 } variant="contained" onClick={sendVoucher}>
            Send Voucher
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

const CreateButton = () => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState(null);

  const { create_voucher, available_vouchers } = useContext(VoucherContext);

  const createVoucher = () => {
    create_voucher()
      .then((url) => {
        setUrl(url);
        setOpen(true);
      })
      .catch((error) => {
        alert(`Something went wrong: ${error}`);
      });
  };

  const VoucherDialog = (props) => {
    const { open, url } = props;
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Voucher URL code</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
            <Typography>{url}</Typography>
            <IconButton onClick={() => copyToClipboard(url)}>
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography sx={{ paddingBottom: 2 }}>
        Press "CREATE" button to create a URL Voucher which you can copy and
        paste into an email yourself.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "left", gap: 3 }}>
        <Button disabled={available_vouchers() <= 0 } variant="contained" onClick={createVoucher}>
          Create
        </Button>
        <VoucherDialog open={open} url={url} />
      </Box>
    </Box>
  );
};

const UploadExcel = () => {

  const [open, setOpen] = useState(false);
  const { available_vouchers } = useContext(VoucherContext);
  return (
    <Box sx={{ padding: 2 }}>
      <BulkEmailDialog open={open} setOpen={setOpen} />
      <Typography sx={{ paddingBottom: 2 }}>
        Paste a list of emails directly from e.g. Excel into a box to invite many users in one step.
        Once you have entered the emails they will be checked for validity and duplication before you give the final approval.
      </Typography>
      <Box>
        <Button disabled={available_vouchers() <= 0 } onClick={() => setOpen(true)}>Press to create Bulk Invitation</Button>
      </Box>
    </Box>
  )
};

const CreateVoucher = (props) => {
  const [value, setValue] = React.useState(0);

  const TabPanel = (props) => {
    const { value, index, children } = props;
    return (
      <Box hidden={value !== index}>
        {value === index && <div>{children}</div>}
      </Box>
    );
  };

  return (
    <Box>
      <Tabs value={value}>
        <Tab label="Bulk Invite" id={0} onClick={() => setValue(0)} />
        <Tab label="Email Voucher" id={1} onClick={() => setValue(1)} />
        <Tab label="Create Voucher" id={2} onClick={() => setValue(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <UploadExcel />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmailButton />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CreateButton />
      </TabPanel>

    </Box>
  );
};

export default CreateVoucher;
