import React from "react";
import Logo from "./Logo";
import World from "./World";

export default () => (
  <>
    <World />
    <Logo style={{ display: "inline", paddingLeft: 10 }} />
  </>
);
