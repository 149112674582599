import React from "react";

import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const YellowButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FDC300",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#E7B200",
  },
  width: "100%",
  marginRight: 0,
}));

export { YellowButton };
