import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  DialogActions,
  Button,
} from "@mui/material";

const UserList = ({ users, onClick }) => (
  <List>
    {users.map((user) => {
      return (
        <ListItem key={user.id} onClick={() => onClick(user)}>
          {user.email}
        </ListItem>
      );
    })}
  </List>
);


const SelectUserDialog = (props) => {
  const { open, onClose, onClick } = props;
  const [users, setUsers] = useState([]);

  const fetchUsers = () => {
    fetch("/admin/user_details.json")
      .then((res) => res.json())
      .then((res) => setUsers(res.users));
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Users</DialogTitle>
      <DialogContent>
        <UserList users={users} onClick={onClick} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUserDialog;
