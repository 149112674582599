import React, { useState, useEffect } from "react";
import ThemeProvider from "../theme/ThemeProvider";
import { Box, Grid } from "@mui/material";
import ElacCard from "./ProductBrowser/ElacCard";
import ProductCard from "./ProductBrowser/ProductCard";
import NoProductCard from "./ProductBrowser/NoProductCard";
import SupportCard from "./ProductBrowser/SupportCard";

const ProductBrowser = () => {
  const [products, setProducts] = useState([]);
  const fetchProducts = () => {
    fetch("/codes/my")
      .then((res) => {
        if (res.ok) return res.json();
        else {
          throw new Error("Not 2xx response", { cause: res });
        }
      })
      .then((res) => {
        setProducts(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clickHandler = (res) => {
    window.location = `/codes/${res}`;
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <ThemeProvider>
      <Box sx={{ flexGrow: 1, margin: 10 }}>
        <Grid container gap={4}>
          {products.map((product) => (
            <ProductCard
              key={product.id}
              onClick={() => clickHandler(product.id)}
              name={product.name}
              product_id={product.id}
            />
          ))}
          {products.length == 0 && <NoProductCard />}
          <ElacCard />
          <SupportCard/>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default ProductBrowser;
