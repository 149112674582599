import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const Logo = (props) => (
  <Box {...props}>
    <Typography
      style={{
        fontSize: 32,
        fontWeight: "300",
        color: "white",
        display: "inline",
      }}
    >
      my
    </Typography>
    <Typography
      style={{
        fontSize: 32,
        fontWeight: "700",
        color: "white",
        display: "inline",
      }}
    >
      ACI
    </Typography>
    <Typography
      style={{
        fontSize: 32,
        fontWeight: "300",
        color: "#FDC300",
        display: "inline",
      }}
    >
      FMA
    </Typography>
  </Box>
);

export default Logo;
