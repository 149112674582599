import React from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Toolbar, IconButton, Typography } from "@mui/material";
import UserMenu from "./MenuBar/UserMenu";
import MenuOptions from "./MenuBar/MenuOptions";
import ThemeProvider from "../theme/ThemeProvider";
import Logo from "../icons/WLogo";

const MenuBar = (props) => {
  return (
    <ThemeProvider>
      <AppBar position="sticky">
        <Toolbar>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton href="/">
              <Logo />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
            <Typography style={{ color: "white", textAlign: "center" }}>
              <MenuOptions menu={props.menu} menu_title={props.menu_title} />
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <UserMenu {...props} />
          </Box>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

MenuBar.propTypes = {
  admin: PropTypes.string.isRequired,
  association_admin: PropTypes.string.isRequired,
  menu: PropTypes.string,
  menu_title: PropTypes.string,
};

export default MenuBar;
